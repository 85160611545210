import { useEffect, useState, useRef } from 'react';
import 'datatables.net-buttons';
import BasicDataTableNet from '../BasicDataTableNet';
import axiosWithAuth from '../utils/axiosWithAuth';

function UsersList (props) {

    const [usersList, setUsersList] = useState([]);
    const [tableData, setTableData] = useState([]);

    //Get all users on page render
    useEffect(() => {
        axiosWithAuth().get(`${process.env.REACT_APP_API_URL}GetAllUsers`)
        .then(response => {
            setUsersList(response.data);

            //check if data exists
            //create table data array for datatables.net format
            if (response.data.length > 0) {
                var data = response.data.map(data => {
                    return [
                        `<a style="text-decoration: none; color: #f7941d; font-weight: bold;" href="/User/${data.userGUID}">${data.firstName}</a>`,
                        data.lastName,
                        data.email,
                        `<a href="/User/${data.userGUID}/AssignUserToDevice"><Button style="margin: 1rem">Assign Device</Button></a>`,
                        // `<a href="/User/${data.userGUID}/Update"><i style="" class="fa fa-solid fa-pen"></i></a>` ,
                        `<a href="/User/${data.userGUID}/Update"><Button style="margin: 1rem">Update Password</Button></a>`,
                              
                    ]
                });
                setTableData(data);
            }
        })
        .catch(error => {

        })
    }, [])

    return(      
        <>
            <BasicDataTableNet tableHeaders={{firstName: 'First Name', lastName: "Last Name", email: "Email", "":"", "edit":""}} data={usersList} tableData={tableData}/>         
        </>
    )

}

export default UsersList;