import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {Link, useNavigate, useParams} from 'react-router-dom';
import ProjUsersList from './ProjUsersList';
import ProjDevicesList from './ProjDevicesList';



function ProjectDetails (props) {

    const navigate = useNavigate();
    const {projectId} = useParams();

    return( 
        <>
        <Container fluid>
        <Button  onClick={() => navigate(-1)}>Back</Button>
                      
            <Row className="my-4">
                <h1>Project Details</h1>
                
            </Row>   
                <Row>
                    <Tabs>

                      <Tab eventKey='Users' title='Users'>
                        <Link to="AssignUserToDevice"><Button style={{margin: "2rem 1rem"}}>Assign User to Device</Button></Link>
                        <ProjUsersList projectId={projectId}/>
                      </Tab>  

                      <Tab eventKey='Devices' title='Devices'>
                        <ProjDevicesList projectId={projectId}/>
                      </Tab>

                </Tabs>
                </Row>      
        </Container>
        </>
    )
}

export default ProjectDetails;