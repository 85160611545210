import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axiosWithAuth from "../../utils/axiosWithAuth";
import ToggleButton from 'react-bootstrap/ToggleButton'
import Spinner from 'react-bootstrap/Spinner';
import {useParams} from 'react-router-dom';
import HeartbeatAlerts from "./HeartbeatAlerts";
import HeartbeatAlertsSMS from "./HeartbeatAlertsSMS";
import DeviceDataAlert from "./DeviceDataAlert";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SubslabDeviceDataAlert from "./SubslabDeviceDataAlert";


function DeviceAlertPreferences (props) {

    const {deviceId} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [heartbeatAlertData, setHeartbeatAlertData] = useState([]);
    const [heartbeatPreloadedData, setHeartbeatPreloadedData] = useState({});
    const [vaporDeviceDataPreloadedEmail, setVaporDeviceDataPreloadedEmail] = useState({});
    const [vaporDeviceDataPreloadedSMS, setVaporDeviceDataPreloadedSMS] = useState({});
    const [subslabDeviceDataPreloadedEmail, setSubslabDeviceDataPreloadedEmail] = useState({});
    const [subslabDeviceDataPreloadedSMS, setSubslabDeviceDataPreloadedSMS] = useState({});

    useEffect(() => {
        getUserNotificationPreferences();
    }, []);

    function getUserNotificationPreferences() {
        axiosWithAuth().get(`${process.env.REACT_APP_API_URL}usernotifications/${deviceId}`) 
        .then(response => {
            setHeartbeatAlertData(response.data.heartbeat)
            getHeartbeatPreloadedData(response.data.heartbeat)

            getDeviceDataPreloaded(response.data);

            //set isLoading: false
            setIsLoading(false);
        })
        .catch(error => {
            console.log("error: ", error);

            //set isLoading: false
            setIsLoading(false);
        })
    }


    function getHeartbeatPreloadedData(data) {
        var emailAlerts = data.filter(x => x.notificationMethod === "email");

        var alertData = {};
  
        if (emailAlerts.length > 0) {
            emailAlerts = emailAlerts[0]; //should only be 1 alert for email, so grab first and only 

            alertData = {
                email: {
                    emailDuration: emailAlerts.duration/60,
                    emailInterval: emailAlerts.interval/60,
                    emailAttribute: emailAlerts.attribute
                }
            }

        } 

        var smsAlerts = data.filter(x => x.notificationMethod === "text");
  
        if (smsAlerts.length > 0) {
            smsAlerts = smsAlerts[0]; //should only be 1 alert for email, so grab first and only 

            alertData.sms = {
                    smsDuration: smsAlerts.duration/60,
                    smsInterval: smsAlerts.interval/60,
                    smsAttribute: smsAlerts.attribute
            }
        } 

        setHeartbeatPreloadedData(alertData);
    }


    function getDeviceDataPreloaded(data) {

        var vaporDeviceDataEmail = {};
        var vaporDeviceDataSMS = {};
        var subslabDeviceDataSMS = {};
        var subslabDeviceDataEmail = {};
        var tempAlertsEmail;
        var pressureAlertsEmail;
        var barometricPressureAlertsEmail;
        var humidityAlertsEmail;
        var ampsAlertsEmail;
        var tempAlertsSMS;
        var pressureAlertsSMS;
        var barometricPressureAlertsSMS;
        var humidityAlertsSMS;
        var ampsAlertsSMS;
        var batteryAlertsEmail;
        var batteryAlertsSMS;
        if (deviceId.startsWith('A')) {
            tempAlertsEmail = data.temp.filter(x => x.notificationMethod === "email");
            pressureAlertsEmail = data.pressure.filter(x => x.notificationMethod === "email");
            barometricPressureAlertsEmail = data.barometric.filter(x => x.notificationMethod === "email");
            humidityAlertsEmail = data.humidity.filter(x => x.notificationMethod === "email");
            ampsAlertsEmail = data.amps.filter(x => x.notificationMethod === "email");
            

            tempAlertsSMS = data.temp.filter(x => x.notificationMethod === "text");
            pressureAlertsSMS = data.pressure.filter(x => x.notificationMethod === "text");
            barometricPressureAlertsSMS = data.barometric.filter(x => x.notificationMethod === "text");
            humidityAlertsSMS = data.humidity.filter(x => x.notificationMethod === "text");
            ampsAlertsSMS = data.amps.filter(x => x.notificationMethod === "text");

            vaporDeviceDataEmail = {
                temp: {
                    low: tempAlertsEmail.filter(x => x.hasOwnProperty('low'))[0],
                    high: tempAlertsEmail.filter(x => x.hasOwnProperty('high'))[0]
                },
                pressure: {
                    low: pressureAlertsEmail.filter(x => x.hasOwnProperty('low'))[0],
                    high: pressureAlertsEmail.filter(x => x.hasOwnProperty('high'))[0]
                },
                barometricPressure: {
                    low: barometricPressureAlertsEmail.filter(x => x.hasOwnProperty('low'))[0],
                    high: barometricPressureAlertsEmail.filter(x => x.hasOwnProperty('high'))[0]
                },
                humidity: {
                    low: humidityAlertsEmail.filter(x => x.hasOwnProperty('low'))[0],
                    high: humidityAlertsEmail.filter(x => x.hasOwnProperty('high'))[0]
                },
                amps: {
                    low: ampsAlertsEmail.filter(x => x.hasOwnProperty('low'))[0],
                    high: ampsAlertsEmail.filter(x => x.hasOwnProperty('high'))[0]
                },
            }

            vaporDeviceDataSMS = {
                temp: {
                    low: tempAlertsSMS.filter(x => x.hasOwnProperty('low'))[0],
                    high: tempAlertsSMS.filter(x => x.hasOwnProperty('high'))[0]
                },
                pressure: {
                    low: pressureAlertsSMS.filter(x => x.hasOwnProperty('low'))[0],
                    high: pressureAlertsSMS.filter(x => x.hasOwnProperty('high'))[0]
                },
                barometricPressure: {
                    low: barometricPressureAlertsSMS.filter(x => x.hasOwnProperty('low'))[0],
                    high: barometricPressureAlertsSMS.filter(x => x.hasOwnProperty('high'))[0]
                },
                humidity: {
                    low: humidityAlertsSMS.filter(x => x.hasOwnProperty('low'))[0],
                    high: humidityAlertsSMS.filter(x => x.hasOwnProperty('high'))[0]
                },
                amps: {
                    low: ampsAlertsSMS.filter(x => x.hasOwnProperty('low'))[0],
                    high: ampsAlertsSMS.filter(x => x.hasOwnProperty('high'))[0]
                },
            }
        }
        if (deviceId.startsWith('S')) {
            pressureAlertsEmail = data.pressure.filter(x => x.notificationMethod === "email");
            tempAlertsEmail = data.temp.filter(x => x.notificationMethod === "email");
            batteryAlertsEmail = data.battery.filter(x => x.notificationMethod === "email");


            pressureAlertsSMS = data.pressure.filter(x => x.notificationMethod === "text");
            tempAlertsSMS = data.temp.filter(x => x.notificationMethod === "text");
            batteryAlertsSMS = data.battery.filter(x => x.notificationMethod === "text");
            

            subslabDeviceDataSMS = {
                battery: {
                    low: batteryAlertsSMS.filter(x => x.hasOwnProperty('low'))[0],
                    high: batteryAlertsSMS.filter(x => x.hasOwnProperty('high'))[0]
                },
                pressure: {
                    low: pressureAlertsSMS.filter(x => x.hasOwnProperty('low'))[0],
                    high: pressureAlertsSMS.filter(x => x.hasOwnProperty('high'))[0]
                },
                
                temp: {
                    low: tempAlertsSMS.filter(x => x.hasOwnProperty('low'))[0],
                    high: tempAlertsSMS.filter(x => x.hasOwnProperty('high'))[0]
                },
                
            }

            subslabDeviceDataEmail = {
                battery: {
                    low: batteryAlertsEmail.filter(x => x.hasOwnProperty('low'))[0],
                    high: batteryAlertsEmail.filter(x => x.hasOwnProperty('high'))[0]
                },
                pressure: {
                    low: pressureAlertsEmail.filter(x => x.hasOwnProperty('low'))[0],
                    high: pressureAlertsEmail.filter(x => x.hasOwnProperty('high'))[0]
                },
                
                temp: {
                    low: tempAlertsEmail.filter(x => x.hasOwnProperty('low'))[0],
                    high: tempAlertsEmail.filter(x => x.hasOwnProperty('high'))[0]
                },
                
            }
        }

        

        
        if (deviceId.startsWith('A')) {
            setVaporDeviceDataPreloadedEmail(vaporDeviceDataEmail);
            setVaporDeviceDataPreloadedSMS(vaporDeviceDataSMS);
        }
        if (deviceId.startsWith('S')) {
            setSubslabDeviceDataPreloadedEmail(subslabDeviceDataEmail);
            setSubslabDeviceDataPreloadedSMS(subslabDeviceDataSMS);
        }
    }

    
    if (isLoading) {
        return (
            <Container className="d-flex align-items-center justify-content-center">      
                <Spinner animation="border"/>
            </Container>
        );
    }
    else { 
        return(      
            <>
                <h2>Device Alerts</h2>
                <br/>
                <HeartbeatAlerts data={heartbeatAlertData} preloadedValues={heartbeatPreloadedData}/>

                <HeartbeatAlertsSMS data={heartbeatAlertData} preloadedValues={heartbeatPreloadedData}/>


                <h3>Device Data Alerts</h3>

                <Tabs>
                    {/* vapor devices only supported for now  */}
                    <Tab eventKey="Email" title="Email">
                        {deviceId.startsWith('A') ? <DeviceDataAlert notificationType="email" notificationMethod="email" preloadedValues={vaporDeviceDataPreloadedEmail} /> : <SubslabDeviceDataAlert notificationType="email" notificationMethod="email" preloadedValues={subslabDeviceDataPreloadedEmail} />}
                    </Tab>
                    
                    <Tab eventKey="Text" title="Text">
                        {deviceId.startsWith('A') ? <DeviceDataAlert notificationType="sms" notificationMethod="text" preloadedValues={vaporDeviceDataPreloadedSMS} /> : <SubslabDeviceDataAlert notificationType="sms" notificationMethod="text" preloadedValues={subslabDeviceDataPreloadedSMS} />}

                    </Tab>
                </Tabs>
                
            </>
        )
    }
    

}

export default DeviceAlertPreferences;