import {useState, useEffect} from "react";
import axiosWithAuth from '../utils/axiosWithAuth';
import {useForm} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import {useNavigate, useParams} from 'react-router-dom';
import AssignUserToDevice from "../Admin-Shared/AssignUserToDevice";


function AssignProjectUserToDevice (props) {

    const {register, handleSubmit, formState: {errors}} = useForm();
    const navigate = useNavigate();
    const {projectId} = useParams();
    const [message, setMessage] = useState("");
    const [usersList, setUsersList] = useState([]);
    const [devicesList, setDevicesList] = useState([]);


    var data = {
        "projectId": projectId
    }

    //Get all users on page render
    useEffect(() => {
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}GetProjUsers`, data)
        .then(response => {
            setUsersList(response.data);
        })
        .catch(error => {

        })
    }, [])

    //Get all orgs on page render
    useEffect(() => {
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}GetProjDevices`, data)
        .then(response => {
            setDevicesList(response.data);
        })
        .catch(error => {

        })
    }, [])


    return(  
        <>  
            <AssignUserToDevice usersList={usersList} devicesList={devicesList}/>
        </>  
    )
}

export default AssignProjectUserToDevice;