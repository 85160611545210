import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axiosWithAuth from "../utils/axiosWithAuth";
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns';


function DataDurationIntervalForm(props) {

    //NON-redux method:
    //function when form is submit to update data - call api
    //in parent, looks like:
    //function: updateState
    //new useffect: watching for changes in [data]

    //redux method:
    //put deviceData in redux state - can be updated on first 'DeviceData' component render with defaultRequest, or updated here with specific duration/interval requests


    const { register, handleSubmit } = useForm();
    const [loading, setLoading] = useState(false);
    const [endDate, setEndUTC] = useState(new Date());

    const onSubmit = (data, e) => {

        setLoading(true);

        var startUTC = new Date();
        startUTC = new Date((endDate - data.duration)).toISOString().replace('Z', '');

        var request = {
            "deviceid": props.deviceId,
            "fromUTC": startUTC,
            "toUTC": endDate,
            "durationInSeconds": parseInt(data.interval)
        }


        //get updated data based on requested duration and interval
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}getdevicedata`, request)
            .then(response => {
                setLoading(false);
                props.updateDeviceData(response.data);
            })
            .catch(error => {
                setLoading(false);
                console.log("error: ", error);
            })

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}getrawdata`, request)
            .then(response => {
                props.updateRawData(response.data);

                //set isLoading: false
                setLoading(false);
            })
            .catch(error => {
                console.log("error: ", error);

                //set isLoading: false
                setLoading(false);
            })
    }

    return (
        <Container>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={4}>
                        <Form.Select {...register("duration")}>
                            <option value="86400000">1 day</option> {/* Each of the values here for the days are # of days * milliseconds in a day. */}
                            <option value="259200000">3 days</option>
                            <option value="604800000" selected="selected">7 days</option>
                            <option value="1209600000">14 days</option>
                            <option value="2592000000">30 days</option>
                            <option value="31536000000">1 year</option>
                        </Form.Select>
                    </Col>

                    <Col md={4}>
                        <Form.Select {...register("interval")}>
                            <option value="3600" selected="selected">1 hour</option>
                            <option value="21600">6 hours</option>
                            <option value="86400">24 hours</option>
                        </Form.Select>
                    </Col>
                    <Col md={4}>
                        <DatePicker
                            showIcon
                            selected={endDate}
                            onChange={(date) => setEndUTC(date)}
                        />
                    </Col>
                    <Col md={4} className="d-flex align-items-center justify-content-center">
                        {loading &&
                            <Spinner animation="border" />
                        }
                        <Button disabled={loading} variant="secondary" type="submit">Submit</Button>
                    </Col>
                </Row>
            </Form>
        </Container>
    );


}

export default DataDurationIntervalForm;