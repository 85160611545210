import {useState, useEffect} from "react";
import axiosWithAuth from '../utils/axiosWithAuth';
import {useForm} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import {useNavigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';


function AssignUserToOrganization (props) {

    const {register, handleSubmit, formState: {errors}} = useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [usersList, setUsersList] = useState([]);
    const [orgsList, setOrgsList] = useState([]);

    //Get all users on page render
    useEffect(() => {
        axiosWithAuth().get(`${process.env.REACT_APP_API_URL}GetAllUsers`)
        .then(response => {
            setUsersList(response.data);
        })
        .catch(error => {

        })
    }, [])

    //Get all orgs on page render
    useEffect(() => {
        axiosWithAuth().get(`${process.env.REACT_APP_API_URL}GetAllOrgs`)
        .then(response => {
            setOrgsList(response.data);
        })
        .catch(error => {

        })
    }, [])

    const onSubmit = (data, e) => {

        setLoading(true);

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}AssignUserToOrg`, data)
        .then(response => {
            console.log(response)
            setLoading(false);
            setMessage("Successfully assigned user to organization")
        })
        .catch(error => {
            setLoading(false);
            setMessage("Failed to assign user to organization")
        })
    }


    return(  
        <>  
        <Container >
            
            <Button style={{margin: "2rem 0rem"}} onClick={() => navigate(-1)}>Back</Button> 

            <Card className=" p-5 d-flex align-items-center justify-content-center">
                           
                <h1 style={{paddingTop: "2rem"}}>Assign user to organization</h1>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="d-flex align-items-center justify-content-center flex-column" controlId="formCreateUser" bsPrefix="formGroup">

                        <div style={{display: "flex"}}>
                            <div style={{padding: "2rem"}}>
                                <Form.Label for='orgs'>Select Organization</Form.Label>
                                <Form.Select name="orgs" {...register("organizationId", {validate: {confirmSelection: v => v != '' || <><i>Please select organization</i><br/></>}})}>
                                    <option selected disabled hidden style={{display: "none"}} value=''></option>
                                    {orgsList.map(org => (
                                        <option value={org.orgGUID}>{org.orgName}</option>
                                    ))}
                                </Form.Select>
                                {errors.organizationId && <i>{errors.organizationId.message}</i>}
                            </div>

                            <div style={{padding: "2rem"}}>
                                <Form.Label for='users'>Select User</Form.Label>
                                <Form.Select name="users" {...register("userId", {validate: {confirmSelection: v => v != '' || <><i>Please select user</i><br/></>}})}>
                                    <option selected disabled hidden style={{display: "none"}} value=''></option>
                                    {usersList.map(user => (
                                        <option value={user.userGUID}>{user.firstName} {user.lastName}</option>
                                    ))}
                                </Form.Select>
                                {errors.userId && <i>{errors.userId.message}</i>}
                            </div>
                    
                            <div style={{padding: "2rem"}}>
                                <Form.Label for='roles'>Select Role</Form.Label>
                                <Form.Select name="roles" {...register("roleType")}>
                                    <option value="viewer" selected="selected">Viewer</option>
                                    <option value="admin">Admin</option>
                                </Form.Select>
                            </div>
                        </div>
                        
                        <Button disabled={loading} type='submit' variant="secondary" style={{marginTop: "1rem"}}>Submit</Button> 
                    </Form.Group>       
                </Form>

                {loading &&
                    <Spinner animation="border"/>
                }

                <p>{message}</p>

            </Card>
        </Container>
    </>  
    )
}

export default AssignUserToOrganization;