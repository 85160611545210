import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import {useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import axiosWithAuth from "../../utils/axiosWithAuth";



function HeartbeatAlertsSMS (props) {

    const [smsButtonText, setsmsButtonText] = useState("Off");
    const [smsFormDisabled, setsmsFormDisabled] = useState(true);
    const {deviceId} = useParams();
    const { userInfo } = useSelector((state) => state.user);
    const [message, setMessage] = useState("");
    const [preloadedValues, setPreloadedValues] = useState({}) //create state for component to update these values on submit/change of existing values

    const { register, handleSubmit, control } = useForm({
        defaultValues: props.preloadedValues.sms
    });

    useEffect(() => {
        setPreloadedValues(props.preloadedValues);

        //set sms form button as enabled/"On" if preloadedValues exist
        if (props.preloadedValues.sms) {
            setsmsFormDisabled(false);
            setsmsButtonText("On");
        }
        
    }, []);

    const onSubmit = (data, e) => {
        setMessage("")

        //updating an alert preference
        if (!smsFormDisabled && preloadedValues.sms) {
            //build request for inserting a NEW alert preference
            var request = {
                "entity": deviceId,
                "value": `${userInfo.phone[0]}`,
                "notificationType": "sms",
                "action": "update",
                "attribute": preloadedValues.sms.smsAttribute,
                "newAttribute":`heartbeat|${data.smsDuration * 60}|text|${data.smsInterval * 60}`
            }
            
            sendHeartbeatAlertRequest(request);   
            console.log("update: ", request);     
        }
        //inserting/creating a NEW alert preference
        else if (!smsFormDisabled) {

            //build request for inserting a NEW alert preference
            var request = {
                "entity": deviceId,
                "attribute": `heartbeat|${data.smsDuration * 60}|text|${data.smsInterval * 60}`,
                "value": `${userInfo.phone[0]}`,
                "notificationType": "sms",
                "action": "insert"
            }
            
            sendHeartbeatAlertRequest(request);   
        }
        //deleting an alert preference, and alert previously existed for user
        if (smsFormDisabled && preloadedValues.sms) {
            //build request for deleting an alert preference
            var request = {
                "entity": deviceId,
                "attribute": preloadedValues.sms.smsAttribute,
                "value": `${userInfo.phone[0]}`,
                "notificationType": "sms",
                "action": "delete"
            }

            sendHeartbeatAlertRequest(request);  
            console.log("delete: ", request);
        }
    }

    function sendHeartbeatAlertRequest(request) {
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}usernotifications`, request)
        .then(response => {
            setMessage("Changes saved successfully")

            //if successfully changed user preferences, update state to use attribute
            if (request.action == "insert") {
                setPreloadedValues({...preloadedValues, sms: {...preloadedValues.sms, smsAttribute: request.attribute}})
            }
            //if successfully changed user preferences, update state to use newAttribute
            else if (request.action == "update") {
                setPreloadedValues({...preloadedValues, sms: {...preloadedValues.sms, smsAttribute: request.newAttribute}})
            }
            //if successfully changed user preferences, update state to remove preloaded values
            else if (request.action == "delete") {
                setPreloadedValues({})
            }
            
        })
        .catch(error => {
            setMessage("Error - changes were not saved")
        })
    }

    const togglesmsPreferences = () =>  {
        if (smsFormDisabled) {
            setsmsButtonText("On");
            setsmsFormDisabled(false);
        }
        else if (!smsFormDisabled){
            setsmsButtonText("Off")
            setsmsFormDisabled(true);
        }
    }
    
    return(      
        <>
            <h4>Text</h4>

            {userInfo.phone.length > 0 ?
                <>
                <Form style={{width: "30%"}} onSubmit={handleSubmit(onSubmit)}>
                
                        <Form.Label>Duration without communication</Form.Label>
                        <Controller
                            name="smsDuration"
                            control={control}
                            defaultValue="24"
                            render={({ field }) => 
                                <Form.Select disabled={smsFormDisabled} {...register("smsDuration")}>
                                    <option value="4">4 hours</option>
                                    <option value="8">8 hours</option>
                                    <option value="12">12 hours</option>
                                    <option value="24">24 hours</option>
                                </Form.Select>
                            }
                        />                    
                            
                        <Form.Label>Alert Frequency</Form.Label>   
                        <Controller
                            name="smsInterval"
                            control={control}
                            defaultValue="24"
                            render={({ field }) => 
                                <Form.Select disabled={smsFormDisabled} {...register("smsInterval")}>
                                    <option value="1" >1 hour</option>
                                    <option value="2">2 hours</option>
                                    <option value="4">4 hours</option>
                                    <option value="8">8 hours</option>
                                    <option value="12">12 hours</option>
                                    <option value="24">24 hours</option>
                                </Form.Select>
                            }
                        />


                        {/* For potential future use for multiple phone numbers */}
                        {/* <Form.Label>Phone Number</Form.Label>
                        {userInfo.phone.map(key => (
                            
                            <Form.Check
                                type="checkbox"
                                id={key}
                                name="numbers"
                                value={key}
                                label={key}
                                {...register("numbers", { validate: v => v.length > 0 })}
                            />
                        ))} */}
                                
                        <div style={{display: "flex", flexDirection:"column"}}>
                            <Button variant="dark" type="submit">Save changes</Button>
                            
                            {/* <ToggleButton variant="secondary" onClick={() => togglesmsPreferences()}>{smsButtonText}</ToggleButton> */}
                            <Form.Check 
                                type="switch"
                                label={smsButtonText}
                                onClick={() => togglesmsPreferences()}
                                checked={!smsFormDisabled}
                            />
                        </div>          
                </Form>   
                <p>{message}</p>     
                </>

            : <p>You must have a number associated with your account in order to set up text alerts</p>}
        </>
    )
}

export default HeartbeatAlertsSMS;