
import {useForm} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import axiosWithAuth from '../utils/axiosWithAuth';
import {useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';



function CreateUser (props) {

    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const { userInfo } = useSelector((state) => state.user)
    const [orgs, setOrgs] = useState([]);
    const [projects, setProjects] = useState([]);

    let orgProjSelect = "";
    if (!userInfo.isSysAdmin) {
        axiosWithAuth().get(`${process.env.REACT_APP_API_URL}GetUserOrgsAndProjects`)
        .then(response => {
            //check for orgs
            var orgs = userInfo.isOrgAdmin.map(x => {
                var orgObject = response.data.organizations.find(o => o.orgGUID == x)
                if (orgObject) {
                    var orgValue = "Org: " + `${orgObject.orgGUID}`
                    return [
                        <option value={orgValue}>Org: {orgObject.orgName}</option>
                    ]
                }
            })
            setOrgs(orgs);

            //check for projects
            var projects = userInfo.isProjAdmin.map(x => {
                var projObject = response.data.projects.find(o => o.projGUID == x)
                if (projObject) {
                    var projValue = "Proj: " + `${projObject.projGUID}`
                    return [
                        <option value={projValue}>Project: {projObject.projName}</option>
                    ]
                }
            })
            setProjects(projects);

        })
        .catch(error => {
            console.log(error);
        })

        orgProjSelect = <div style={{padding: "2rem"}}>
            <Form.Label for='associatedorgorproj'>Select Associated Organization or Project</Form.Label>
            <Form.Select name="associatedorgorproj" {...register("associatedorgorproj", {validate: {confirmSelection: v => v != '' || <><i>Please select an organization</i><br/></>}})}>
                <option selected disabled hidden style={{display: "none"}} value=''></option>
                {orgs}
                {projects}
            </Form.Select>
            {errors.associatedorgorproj && <i>{errors.associatedorgorproj.message}</i>}
        </div>
        {errors.associatedorgorproj && <i>{errors.associatedorgorproj.message}</i>};
    }

    const onSubmit = (data, e) => {

        setLoading(true);

        var request = {
            "email": data.entity.toLower(),
            "value": data.value,
            "firstName": data.firstName,
            "lastName": data.lastName,
            "action":"create"
        }

        if (!userInfo.isSysAdmin) {
            if (data.associatedorgorproj.startsWith('Org: ')) {
                request["organizationId"] = data.associatedorgorproj.slice(5)
            } 
            else if (data.associatedorgorproj.startsWith('Proj: ')) {
                request["projectId"] = data.associatedorgorproj.slice(6)
            }
        }

        axiosWithAuth().post(`${process.env.REACT_APP_API_AUTH_URL}user`, request)
        .then(response => {
            console.log("response: ", response)
            setLoading(false);
            setMessage("User created successfully");
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
            if (error.response.data === "Conflict: email already exists") {
                setMessage("User not created: Email already in database");
            }
            else {
                setMessage("Error - User was not created");
            }
        })

    }


    return(  
        <>  
            <Container >

                <Button style={{margin: "2rem 0rem"}} onClick={() => navigate(-1)}>Back</Button> 

                <Card className=" p-5 d-flex align-items-center justify-content-center">
                               
                    <h1 style={{paddingTop: "2rem"}}>Create User</h1>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="d-flex align-items-center justify-content-center flex-column" controlId="formCreateUser" bsPrefix="formGroup">

                            <div style={{display: "flex"}}>
                                <div style={{padding: "2rem"}}>
                                    <Form.Label for='entity'>Email</Form.Label>
                                    <Form.Control placeholder='Enter username' type='text' name='entity' {...register('entity', {required: <p>Email is required</p>})}/>

                                    {errors.entity && <i>{errors.entity.message}</i>}
                                
                                    <Form.Label for='value'>Password</Form.Label>
                                    <Form.Control placeholder='Enter password' type='password' name='value' {...register('value', {required: <p>Password is required</p>})}/>
                                
                                    {errors.value && <i>{errors.value.message}</i>}
                                </div>

                                <div style={{padding: "2rem"}}>
                                    <Form.Label for='firstName'>First Name</Form.Label>
                                    <Form.Control placeholder='Enter first name' type='text' name='firstName' {...register('firstName', {required: <p>First name is required</p>})}/>

                                    {errors.firstName && <i>{errors.firstName.message}</i>}
                                
                                    <Form.Label for='lastName'>Last Name</Form.Label>
                                    <Form.Control placeholder='Enter last name' type='text' name='lastName' {...register('lastName', {required: <p>Last name is required</p>})}/>                 
                                    
                                    {errors.lastName && <i>{errors.lastName.message}</i>}
                                </div>

                                {orgProjSelect}

                            </div>
                            <Button disabled={loading} type='submit' variant="secondary" style={{marginTop: "1rem"}}>Create</Button> 
                        </Form.Group>       
                    </Form>

                    {loading &&
                        <Spinner animation="border"/>
                    }
                    
                    <p>{message}</p>


                </Card>
            </Container>
        </>  
    )
}

export default CreateUser;