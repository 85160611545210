import React, { useEffect, useState, useRef } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";
import Table from 'react-bootstrap/Table';
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactDomServer from 'react-dom/server';
import $, { data } from 'jquery';

function DevicesListTable (props) {

    $.DataTable = require('datatables.net');
    const tableRef = useRef();
    var dataTableDataSet = [];
    var dataTableColumns = [];

    //check if data exists
    if (props.data.length > 0) {
        //set table headers and keys for all properties in dataset by device type
        var tableHeaders = [];
        var dataKeys = []

            //Vapor device
            if (props.data[0].deviceType == 'vapor') {
                tableHeaders = [
                    'Amps',
                    'Pressure',
                    'Temperature',
                    'Humidity',
                    'Barometric pressure'
                ]
                dataKeys = [
                    'amps',
                    'pressure',
                    'temperature',
                    'humidity',
                    'barometric'
                ]
                
            }
            //Sub slab device
            else if (props.data[0].deviceType == 'subslab') { 
                tableHeaders = [
                    'Pressure', 
                    'Temperature'
                ]
                dataKeys = [
                    'pressure',
                    'temperature'
                ]   
            }
            //Radon device
            else if (props.data[0].deviceType == 'radon') {
                tableHeaders = [
                    'Strike Count',
                    'Temperature',
                    'Humidity',
                    'Bias'
                ]
                dataKeys = [
                    'strikecount',
                    'temperature',
                    'humidity',
                    'bias'
                ]
            }

        //format data for datatables.net
        dataTableDataSet = props.data.map(data => {
            if (data.data != null) {

                var tags = [];
                //get org and project tags
                if (data.organization.length > 0) {
                    data.organization.forEach(x => tags.push(x.orgName));
                }
                if (data.project.length > 0) {
                    data.project.forEach(x => tags.push(x.projectName));
                }

                var tagsTableValue = "";
                tags.forEach(x => tagsTableValue += `<p style="color: #4369b2; font-weight: bold">${x}</p>`);

                return(
                [
                    tagsTableValue,
                    `<a style="text-decoration: none; color: #f7941d; font-weight: bold;" href="/DeviceData/${data.deviceId}">${data.deviceId}</a>`,
                    data.deviceType.charAt(0).toUpperCase() + data.deviceType.slice(1),
                    `<i style="color: ${data.status}" class="fa fa-solid fa-circle"></i>`,
                    
                    ...dataKeys.map(key => {
                        return data.data[key]
                    }),
                    new Date(data.lastSensedUTC).toLocaleDateString() + " " + new Date(data.lastSensedUTC).toLocaleTimeString(),
                        
                ])
            }
            else {
                return( 
                [
                    `<a style="text-decoration: none; color: #f7941d; font-weight: bold;" href="/DeviceData/${data.deviceId}">${data.deviceId}</a>`,
                    data.deviceType.charAt(0).toUpperCase() + data.deviceType.slice(1),
                    `<i style="color: ${data.status}" class="fa fa-solid fa-circle"></i>`,

                    `<p>N/A</p>`,
                    `<p>N/A</p>`,
                    `<p>N/A</p>`,
                    `<p>N/A</p>`,
                    `<p>N/A</p>`
                ])
            }
       
        })

        dataTableColumns = [
            {title: "Organization/Project"},
            {title: "Device ID"},
            {title: "Device Type"},
            {title: "Device Status"},
            ...tableHeaders.map(key => (
                {title: key}
            )),
            {title: "Last Sensed UTC"}
        ]

        
    }


    
    useEffect(() => {
        const table = $(tableRef.current).DataTable(
            {
                data: dataTableDataSet,
                columns: dataTableColumns,
                destroy: true,
                order: [0, 'desc'],
                columnDefs: [{
                    "defaultContent": "-",
                    "targets": "_all",
                  }],
               
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            console.log("Table destroyed")
            table.destroy()
        }
    }, [props.data])


    return(      
        <>
            <Container fluid  style={{marginTop: "2rem"}}>
                <Table striped hover className="display" width="100%" ref={ tableRef }></Table>
            </Container>
        </>
    )
}

export default DevicesListTable;