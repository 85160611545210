import { configureStore } from '@reduxjs/toolkit'
import userReducer from './Authentication/userSlice';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
  user: userReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getdefaultMiddleware) => getdefaultMiddleware({serializableCheck: false}).concat(logger),
})

export const persistor = persistStore(store);


// export default configureStore({
//   reducer: {
//     user: userReducer
//   },
//   middleware: (getdefaultMiddleware) => getdefaultMiddleware().concat(logger),
// })
