
import AssignUserToOrganization from "./AssignUserToOrganization";
import DeviceProvision from "./DeviceProvision";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import OrganizationsList from "./OrganizationsList";
import UsersList from "./UsersList";
import {Link, useNavigate} from 'react-router-dom';



function SysAdminDashboard (props) {


    return(  
        <>
        <Container fluid>
            <Row className="my-4">
                <h1>Admin Dashboard</h1>
            </Row>     
                <Row>
                    <Tabs>

                      <Tab eventKey='Organizations' title='Organizations'>
                        
                        <Link to="/CreateOrganization"><Button style={{margin: "2rem 1rem"}}>Create New Organization</Button></Link>
                        <Link to="/AssignUserToOrganization"><Button style={{margin: "2rem 1rem"}}>Assign User To Organization</Button></Link>
                        <OrganizationsList/>                      
                      </Tab>   

                      <Tab eventKey='Users' title='Users'>
                        <Link to="/CreateUser"><Button style={{margin: "2rem 1rem"}}>Create New User</Button></Link>
                        <UsersList/>
                      </Tab>  

                      <Tab eventKey='Devices' title='Devices'>
                        <DeviceProvision/>
                      </Tab>  

                </Tabs>
                </Row>      
        </Container>
        </>
    )
}

export default SysAdminDashboard;