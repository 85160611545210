import { useEffect, useState, useRef } from 'react';
import 'datatables.net-buttons';
import BasicDataTableNet from '../BasicDataTableNet';
import axiosWithAuth from '../utils/axiosWithAuth';

function OrgUsersList (props) {

    const [tableData, setTableData] = useState([]);

    var data = {
        "organizationId": props.organizationId
    }


    //Get all users on page render
    useEffect(() => {

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}GetOrgUsers`, data)
        .then(response => {
            //check if data exists
            //create table data array for datatables.net format
            if (response.data.length > 0) {
                var data = response.data.map(data => {
                    return [
                        //`<a style="text-decoration: none; color: #f7941d; font-weight: bold;" href="/User/${data.userGUID}">${data.firstName}</a>`,
                        data.firstName,
                        data.lastName,
                        data.email,
                        data.role       
                    ]
                });
                setTableData(data);
            }
        })
        .catch(error => {
            console.log("ERROR", error)
        })
    }, [])

    return(      
        <>
            <BasicDataTableNet tableHeaders={{firstName: 'First Name', lastName: "Last Name", email: "Email", role: "Role"}} tableData={tableData}/>         
        </>
    )

}

export default OrgUsersList;