import './App.css';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';  
import DeviceData from './Device/DeviceData';
import DeviceDataChart from './Device/DeviceDataChart';
import DeviceDataTable from './Device/DeviceDataTable';
import DevicesList from './Dashboard/DevicesList';
import Navigation from './Navigation';
import Login from './Authentication/Login';
import UserSettings from './User/UserSettings';
import SysAdminDashboard from './SysAdmin/SysAdminDashboard';
import {Route, Routes, Navigate, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import { useEffect } from 'react';
import {ProtectedRoute} from './utils/ProtectedRoute';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CreateOrganization from './SysAdmin/CreateOrganization';
import CreateUser from './SysAdmin/CreateUser';
import CreateProject from './SysAdmin/CreateProject';
import OrganizationDetails from './Organization/OrganizationDetails';
import ProjectDetails from './Project/ProjectDetails';
import AssignUserToProject from './SysAdmin/AssignUserToProject';
import AdminDashboard from './AdminDashboard';
import AssignUserToOrganization from './SysAdmin/AssignUserToOrganization';
import AssignProjectUserToDevice from './Project/AssignProjectUserToDevice';
import AssignDeviceToProject from './Organization/AssignDeviceToProject'
import AssignDeviceToOrganization from './SysAdmin/AssignDeviceToOrganization';
import UserAccess from './Admin-User-View/UserAccess';
import AssignDirectUserToDevice from './SysAdmin/AssignDirectUserToDevice';
import UserOverview from './Admin-User-View/UserOverview';


function App() {
  const navigate = useNavigate();
  const { loginSuccess, userInfo } = useSelector((state) => state.user)


  useEffect(() => {
    
  }, [loginSuccess])

  return (
    <>
    

    {/* can replace localStorage call here to check if userInfo exists, after this functionality to grab userInfo is completed */}
    {loginSuccess ? <Navigation/> : null}

    <Container>
    <Routes>
      <Route path="/"element={<Navigate to="/Login" replace />}/> {/* route to Login as default for now, logic for rerouting logged in users in Login component */}
      <Route path="/Login" element={<Login/>}/>
      <Route 
        path="/Devices" 
        element={
          <ProtectedRoute>
            <DevicesList/>
          </ProtectedRoute>
        }
      />    
       <Route 
        path="/Settings" 
        element={
          <ProtectedRoute>
            <UserSettings/>
          </ProtectedRoute>
        }
      />   
      <Route 
        path="/Admin" 
        element={
          userInfo.isSysAdmin ? (
            <ProtectedRoute>
              <SysAdminDashboard/>
            </ProtectedRoute>
          ) : (
            <ProtectedRoute>
              <AdminDashboard/>
            </ProtectedRoute>
          )
        
        }
      />  
      <Route 
        path="/CreateOrganization" 
        element={
          <ProtectedRoute>
            <CreateOrganization/>
          </ProtectedRoute>
        }
      />  
       <Route 
        path="/AssignUserToOrganization" 
        element={
          <ProtectedRoute>
            <AssignUserToOrganization/>
          </ProtectedRoute>
        }
      />  
      <Route 
        path="/CreateUser" 
        element={
          <ProtectedRoute>
            <CreateUser/>
          </ProtectedRoute>
        }
      />
      <Route 
        path="/Organization/:organizationId/CreateProject" 
        element={
          <ProtectedRoute>
            <CreateProject/>
          </ProtectedRoute>
        }
      /> 

      <Route 
        path="/Organization/:organizationId"
        element={
          <ProtectedRoute>
            <OrganizationDetails/>
          </ProtectedRoute>
        }
      />

      <Route 
        path="/Project/:projectId"
        element={
          <ProtectedRoute>
            <ProjectDetails/>
          </ProtectedRoute>
        }
      />

       <Route 
        path="/Organization/:organizationId/Project/:projectId/AssignUserProject" 
        element={
          <ProtectedRoute>
            <AssignUserToProject/>
          </ProtectedRoute>
        }
      /> 

      <Route 
        path="/Organization/:organizationId/Project/:projectId/AssignDeviceProject" 
        element={
          <ProtectedRoute>
            <AssignDeviceToProject/>
          </ProtectedRoute>
        }
      /> 

      <Route 
        path="/Organization/:organizationId/AssignDeviceOrganization" 
        element={
          <ProtectedRoute>
            <AssignDeviceToOrganization/>
          </ProtectedRoute>
        }
      /> 

      <Route 
        path="/Project/:projectId/AssignUserToDevice" 
        element={
          <ProtectedRoute>
            <AssignProjectUserToDevice/>
          </ProtectedRoute>
        }
      /> 

      <Route 
        path="/User/:userId/AssignUserToDevice" 
        element={
          <ProtectedRoute>
            <AssignDirectUserToDevice/>
          </ProtectedRoute>
        }
      /> 

      <Route 
        path="/User/:userId" 
        element={
          <ProtectedRoute>
            <UserAccess/>
          </ProtectedRoute>
        }
      /> 

      <Route 
        path="/User/:userId/Update" 
        element={
          <ProtectedRoute>
            <UserOverview/>
          </ProtectedRoute>
        }
      /> 

      <Route path="/SampleDeviceData" element={<DeviceData/>}/> 
      <Route exact path="/DeviceData/:deviceId" element={<DeviceData/>}/>
    </Routes>
    </Container>

        <p className="Footer">v{process.env.REACT_APP_VERSION}</p>
    </>
  );
}

export default App;
