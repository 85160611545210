import { useEffect, useState, useRef } from 'react';
import 'datatables.net-buttons';
import BasicDataTableNet from '../BasicDataTableNet';
import axiosWithAuth from '../utils/axiosWithAuth';

function OrgDevicesList(props) {

    const [tableData, setTableData] = useState([]);

    var data = {
        "organizationId": props.organizationId
    }


    //Get all users on page render
    useEffect(() => {

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}GetOrgDevices`, data)
            .then(response => {
                //check if data exists
                //create table data array for datatables.net format
                if (response.data.length > 0) {
                    var data = response.data.map(data => {
                        return [
                            `<a style="text-decoration: none; color: #f7941d; font-weight: bold;" href="/DeviceData/${data}">${data}</a>`,

                        ]
                    });
                    setTableData(data);
                }
            })
            .catch(error => {
                console.log("ERROR", error)
            })
    }, [])

        return (
            <>
                <BasicDataTableNet tableHeaders={{ name: 'Device Name' }} tableData={tableData} />
            </>
        )
}

export default OrgDevicesList;