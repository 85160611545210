import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ToggleButton from 'react-bootstrap/ToggleButton';
import {useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import axiosWithAuth from "../../utils/axiosWithAuth";



function HeartbeatAlerts (props) {

    const [emailButtonText, setEmailButtonText] = useState("Off");
    const [emailFormDisabled, setEmailFormDisabled] = useState(true);
    const {deviceId} = useParams();
    const { userInfo } = useSelector((state) => state.user);
    const [message, setMessage] = useState("");
    const [preloadedValues, setPreloadedValues] = useState({}) //create state for component to update these values on submit/change of existing values

    const { register, handleSubmit, control } = useForm({
        defaultValues: props.preloadedValues.email
    });

    useEffect(() => {
        setPreloadedValues(props.preloadedValues);

        //set email form button as enabled/"On" if preloadedValues exist
        if (props.preloadedValues.email) {
            setEmailFormDisabled(false);
            setEmailButtonText("On");
        }
        
    }, []);

    const onSubmit = (data, e) => {
        setMessage("")

        //updating an alert preference
        if (!emailFormDisabled && preloadedValues.email) {
            //build request for inserting a NEW alert preference
            var request = {
                "entity": deviceId,
                "value": `${userInfo.email}`,
                "notificationType": "email",
                "action": "update",
                "attribute": preloadedValues.email.emailAttribute,
                "newAttribute":`heartbeat|${data.emailDuration * 60}|email|${data.emailInterval * 60}`
            }
            
            sendHeartbeatAlertRequest(request);        
        }
        //inserting/creating a NEW alert preference
        else if (!emailFormDisabled) {

            //build request for inserting a NEW alert preference
            var request = {
                "entity": deviceId,
                "attribute": `heartbeat|${data.emailDuration * 60}|email|${data.emailInterval * 60}`,
                "value": `${userInfo.email}`,
                "notificationType": "email",
                "action": "insert"
            }
            
            sendHeartbeatAlertRequest(request);   
        }
        //deleting an alert preference, and alert previously existed for user
        if (emailFormDisabled && preloadedValues.email) {
            //build request for deleting an alert preference
            var request = {
                "entity": deviceId,
                "attribute": preloadedValues.email.emailAttribute,
                "value": `${userInfo.email}`,
                "notificationType": "email",
                "action": "delete"
            }

            sendHeartbeatAlertRequest(request);  
        }
    }

    function sendHeartbeatAlertRequest(request) {
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}usernotifications`, request)
        .then(response => {
            setMessage("Changes saved successfully")

            //if successfully changed user preferences, update state to use attribute
            if (request.action == "insert") {
                setPreloadedValues({...preloadedValues, email: {...preloadedValues.email, emailAttribute: request.attribute}})
            }
            //if successfully changed user preferences, update state to use newAttribute
            else if (request.action == "update") {
                setPreloadedValues({...preloadedValues, email: {...preloadedValues.email, emailAttribute: request.newAttribute}})
            }
            //if successfully changed user preferences, update state to remove preloaded values
            else if (request.action == "delete") {
                setPreloadedValues({})
            }
            
        })
        .catch(error => {
            setMessage("Error - changes were not saved")
        })
    }

    const toggleEmailPreferences = () =>  {
        if (emailFormDisabled) {
            setEmailButtonText("On");
            setEmailFormDisabled(false);
        }
        else if (!emailFormDisabled){
            setEmailButtonText("Off")
            setEmailFormDisabled(true);
        }
    }
    
    return(      
        <>
            <h3>Heartbeat</h3>
            <p>Heart beat alerts will notify when a device has not communicated within a specified amount of time.</p>
            <p>Set the frequency you would like to be notified with this alert.</p>
            <br/>

            <h4>Email</h4>
            <Form style={{width: "30%"}} onSubmit={handleSubmit(onSubmit)}>
               
                    <Form.Label>Duration without communication</Form.Label>
                    <Controller
                        name="emailDuration"
                        control={control}
                        defaultValue="24"
                        render={({ field }) => 
                            <Form.Select disabled={emailFormDisabled} {...register("emailDuration")}>
                                <option value="4">4 hours</option>
                                <option value="8">8 hours</option>
                                <option value="12">12 hours</option>
                                <option value="24">24 hours</option>
                            </Form.Select>
                        }
                    />                    
                         
                    <Form.Label>Alert Frequency</Form.Label>   
                    <Controller
                        name="emailInterval"
                        control={control}
                        defaultValue="24"
                        render={({ field }) => 
                            <Form.Select disabled={emailFormDisabled} {...register("emailInterval")}>
                                <option value="1" >1 hour</option>
                                <option value="2">2 hours</option>
                                <option value="4">4 hours</option>
                                <option value="8">8 hours</option>
                                <option value="12">12 hours</option>
                                <option value="24">24 hours</option>
                            </Form.Select>
                        }
                    />
                            
                    <div style={{display: "flex", flexDirection:"column"}}>
                        <Button variant="dark" type="submit">Save changes</Button>
                        
                        {/* <ToggleButton variant="secondary" onClick={() => toggleEmailPreferences()}>{emailButtonText}</ToggleButton> */}
                        <Form.Check 
                            type="switch"
                            label={emailButtonText}
                            onClick={() => toggleEmailPreferences()}
                            checked={!emailFormDisabled}
                        />
                    </div>          
            </Form>   
            <p>{message}</p>                
        </>
    )
}

export default HeartbeatAlerts;