import { useEffect, useState, useRef } from 'react';
import 'datatables.net-buttons';
import BasicDataTableNet from '../BasicDataTableNet';
import axiosWithAuth from '../utils/axiosWithAuth';

function OrganizationsList (props) {

    const [orgsList, setOrgsList] = useState([]);
    const [tableData, setTableData] = useState([]);
 
    //Get all orgs on page render
    useEffect(() => {
        axiosWithAuth().get(`${process.env.REACT_APP_API_URL}GetAllOrgs`)
        .then(response => {
            setOrgsList(response.data);

            //check if data exists
            //create table data array for datatables.net format
            if (response.data.length > 0) {
                var data = response.data.map(data => {
                    return [
                        `<a style="text-decoration: none; color: #f7941d; font-weight: bold;" href="/Organization/${data.orgGUID}">${data.orgName}</a>`,
                        data.status,
                        `<a href="/Organization/${data.orgGUID}/AssignDeviceOrganization"><Button>Assign Device</Button></a>`         
                    ]
                });
                setTableData(data);
            }
        })
        .catch(error => {

        })
    }, [])

    return(      
        <>
            <BasicDataTableNet tableHeaders={{orgName: 'Organization Name', status: 'Status', "":""}} tableData={tableData}/>         
        </>
    )

}

export default OrganizationsList;