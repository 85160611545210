import { useEffect, useState } from 'react';
import { logOut } from './Authentication/userSlice';
import {Link, useNavigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    Navbar,
    NavDropdown,
    Nav,
  } from 'react-bootstrap'
  import Container from 'react-bootstrap/Container';
 

function Navigation (props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.user)

    const onLogOut = () => {
        dispatch(logOut());

        navigate('/Login');
    }



    return(      
        <>     
            {/* <img style={{width: "25%", height: "auto"}} src="./VS Full Logo.png"/>          */}
            {/* <div style={{display: "flex"}}> */}
            <Navbar variant="dark" style={{backgroundColor: "#4369b2", display: "flex", justifyContent:"space-between", alignItems:"center", marginBottom: "2rem", padding: "1rem"}} collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand><Link to="/"><img style={{width: "150px", height:"auto", marginRight: "3rem"}} src='/VS FULL LOGO_Orange and White.png'/></Link></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="container-fluid">
                                                         
                            <Nav.Link className="mr-auto" as={Link} to="/Devices" style={{fontWeight: "bold"}}>
                                Devices
                            </Nav.Link>

                            {/* sys admin */}
                            {userInfo.isSysAdmin ? 
                              <Nav.Link className="mr-auto" as={Link} to="/Admin" style={{fontWeight: "bold", marginLeft: "1rem"}} >
                                Admin
                              </Nav.Link>   
                              :
                              ""               
                            }

                            {/* {userInfo.isSysAdmin || (userInfo.isOrgAdmin && userInfo.isProjAdmin) && (userInfo.isOrgAdmin.length > 0 || userInfo.isProjAdmin.length > 0)} */}
                            
                            {/* org admin or project admin */}
                            {userInfo.isOrgAdmin && userInfo.isProjAdmin && (userInfo.isOrgAdmin.length > 0 || userInfo.isProjAdmin.length > 0) && !userInfo.isSysAdmin ? 
                                 <Nav.Link className="mr-auto" as={Link} to="/Admin" style={{fontWeight: "bold", marginLeft: "1rem"}} >
                                 Admin
                                </Nav.Link>
                              : 
                              ""
                            }
                            
                            {/* project admin
                            {userInfo.isProjAdmin && !userInfo.isOrgAdmin && !userInfo.isSysAdmin ? 
                                <>Project Admin</>
                              : 
                              ""
                            } */}

                            <div className="d-flex ms-auto">
                                <Navbar.Text>{userInfo.firstName}</Navbar.Text>

                                <NavDropdown align="end" className="font-weight-bold" title={<i class="fa fa-sharp fa-solid fa-gear"/>} id="collasible-nav-dropdown">
                                    <NavDropdown.Item style={{fontWeight: "bold"}} onClick={() => navigate('/Settings')}>Settings</NavDropdown.Item>
                                    <NavDropdown.Item style={{fontWeight: "bold"}} onClick={onLogOut}>Logout</NavDropdown.Item>                                   
                                </NavDropdown>
                            </div>

                        </Nav> 

                     
                        {/* <p>{userInfo}</p> */}
                    </Navbar.Collapse>
                   
                </Container>
            </Navbar>
            {/* </div> */}
        </>
    )
}

export default Navigation;