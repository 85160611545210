import { useEffect, useState, useRef } from 'react';
import Table from 'react-bootstrap/Table';
import {useParams} from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-buttons';

function BasicDataTableNet (props) {
    
    $.DataTable = require('datatables.net');
    require( 'datatables.net-buttons/js/dataTables.buttons.min' );
    const jzip = require( 'jszip');
    require( 'datatables.net-buttons/js/buttons.html5.min' );
    window.JSZip = jzip;

    const tableRef = useRef();
    var dataTableDataSet = [];
    var dataTableColumns = [];

    //check if data exists
    if (props.tableData.length > 0) {
        //grab all keys for all properties in dataset
        var tableHeaders = Object.values(props.tableHeaders);
        
        //var tableHeadersKeys = Object.keys(props.tableHeaders);
        //format data for datatables.net
        // dataTableDataSet = props.data.map(data => {
        //     return tableHeaders.map((element, index) => {
        //         return data[tableHeadersKeys[index]]
        //     });
        // })

        dataTableDataSet = props.tableData;

        dataTableColumns = [
            ...tableHeaders.map(key => (
                {title: key}
            ))
        ]
    }
    

    useEffect(() => {
        const table = $(tableRef.current).DataTable(
            {
                data: dataTableDataSet,
                columns: dataTableColumns,
                destroy: true,
                order: [0, 'desc'],
                //dom: 'Bfrtip', //add 'l' back in to get length changing input control
                columnDefs: [{
                    "defaultContent": "-",
                    "targets": "_all",
                }],
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    }, [props.tableData])

    if (props.tableData.length == 0) {
        return(
            <div>
                <h1>No Data</h1>
            </div>
        )
    }
    else {
        return(      
            <>
                <div>
                    <Table striped hover className="display" width="100%" ref={ tableRef }></Table>
                </div>
                
            </>
        )
    }
}

export default BasicDataTableNet;