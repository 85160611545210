import { useEffect, useState, useRef } from 'react';
import txt from '../Device/data.json';
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';


import $ from 'jquery';
import 'datatables.net-buttons';
// import 'datatables.net-dt/css/jquery.dataTables.css'
// import 'datatables.net-buttons-dt/css/buttons.dataTables.css'

function DeviceDataTable (props) {

    const {deviceId} = useParams();
    
    $.DataTable = require('datatables.net');
    require( 'datatables.net-buttons/js/dataTables.buttons.min' );
    const jzip = require( 'jszip');
    require( 'datatables.net-buttons/js/buttons.html5.min' );
    window.JSZip = jzip;

    const tableRef = useRef();
    var dataTableDataSet = [];
    var dataTableColumns = [];

    //check if data exists
    if (props.data.length > 0) {
        //grab all keys for all properties in dataset
        var tableHeaders = Object.keys(props.data[0].data);

        //format data for datatables.net
        dataTableDataSet = props.data.map(data => (
            [
                moment.utc(data.sensedUTC).tz("America/Kentucky/Louisville").format('MM-DD-YYYY LT'),
                data.data[tableHeaders[0]],
                data.data[tableHeaders[1]],
                data.data[tableHeaders[2]],
                data.data[tableHeaders[3]],
                data.data[tableHeaders[4]]
            ]
        ))

        //fix format for table headers (<br> is for line breaks to put measurements on bottom)
        if (deviceId.startsWith('A')) {
            tableHeaders[0] = "Current<br>(amps)";
            tableHeaders[1] = "Pressure<br>(in. WC)";
            tableHeaders[2] = "Temperature<br>(F)";
            tableHeaders[3] = "Humidity<br>(%rH)";
            tableHeaders[4] = "Barometric Pressure<br>(mbar)";
        }
        /*if (deviceId.startsWith('S')) {
            tableHeaders[1] = "Pressure<br>(in. WC)";
            tableHeaders[2] = "Temperature<br>(F)";
            tableHeaders[3] = "Battery<br>(%)";
            tableHeaders[4] = "Voltage<br>(Volts)";
        }*/

        dataTableColumns = [
            {title: "Date", "type": "date"},
            ...tableHeaders.map(key => (
                {title: key}
            ))
        ]

        
    }
    

    useEffect(() => {
        const table = $(tableRef.current).DataTable(
            {
                data: dataTableDataSet,
                columns: dataTableColumns,
                destroy: true,
                order: [0, 'desc'],
                dom: 'Bfrtip', //add 'l' back in to get length changing input control
                buttons: [
                    {
                        extend:'excel',
                        title: `${deviceId}`
                    },
                    {
                        extend:'csv',
                        title: `${deviceId}`
                    },
                    {
                        extend:'copy',
                        title: `${deviceId}`
                    },
                ],
                columnDefs: [
                    {
                        targets: "_all",
                        className: 'dt-head-center dt-body-center'
                    }
                ]
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            console.log("Table destroyed")
            table.destroy()
        }
    }, [props.data])

    if (props.data.length == 0) {
        return(
            <div>
                <h1>No Data</h1>
            </div>
        )
    }
    else {
        return(      
            <>
                {/* datatables.net working example below */}
                <div>
                    <Table striped hover className="display dt-head-center" width="100%" ref={ tableRef }></Table>
                </div>
                
            </>
        )
    }
}

export default DeviceDataTable;
