import React from 'react';
import {useParams, Redirect, useNavigate} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {loginUser, getUserInfo} from '../Authentication/userSlice';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Spinner from 'react-bootstrap/Spinner';

function Login(props) {

    const {register, handleSubmit, formState: {errors}} = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error, userInfo, loginSuccess } = useSelector((state) => state.user)

    // redirect authenticated user to dashboard screen
    useEffect(() => {
        if (loginSuccess) {

            //call to grab userInfo from api
            dispatch(getUserInfo());

            //redirect to devices list
            navigate('/Devices')
            //navigate('/SampleDeviceData')
        }
    }, [navigate, loginSuccess])

    const onSubmit = (data, e) => {
        dispatch(loginUser(data))
        
        

        //e.target.reset();
    }

    return (
        <Container id="loginBackground" className="d-flex align-items-center justify-content-center">
        <Card style={{ width: "40%" }} className=" p-5 d-flex align-items-center justify-content-center">
            <Card.Img variant="top" style={{width: "50%", marginTop: "1rem"}} src='./VS Full Logo.png'/>
        
            <h1 style={{paddingTop: "2rem"}}>Login</h1>
            <Form style={{width: "40%"}}  onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="d-flex align-items-center justify-content-center flex-column" controlId="formLogin" bsPrefix="formGroup">
                    <Form.Label for='email'>Username</Form.Label>
                    <Form.Control placeholder='Enter username' type='text' name='email' {...register('email', {required: "Username is required"})}/>

                    {errors.email && <i>{errors.email.message}</i>}
                
                    <Form.Label for='key'>Password</Form.Label>
                    <Form.Control placeholder='Enter password' type='password' name='key' {...register('key', {required: "Password is required"})}/>
                
                    
                    {errors.key && <i>{errors.key.message}</i>}

                    <Button type='submit' variant="secondary" style={{marginTop: "1rem"}} disabled={loading}>Login</Button>

                        {/* Comment below out until functionality is built */}
                        {/*
                            <a
                                href="#top"
                                className="text-gray-800"
                            >
                                Forgot password?
                            </a>
                        */} 
                </Form.Group>
            </Form>

            {loading &&
                <Spinner animation="border"/>
            }

            {error ? <h3 style={{color: "red"}}>{error}</h3> : null}



        </Card>
        </Container>

        // <Container id="loginContainer">
        //     <Row>
        //         <Col>     
        //             <Image src='/new-construction-with-device-1024x768.png' style={{maxWidth: "100%", width: "auto", height:"99vh", objectFit:"cover"}}/>               
        //         </Col>
        //         <Col>
        //             <Container className="d-flex align-items-center justify-content-center">
        //                 <Card style={{ width: "50%" }} className=" p-4 d-flex align-items-center justify-content-center">
        //                     <Card.Img variant="top" style={{width: "50%", marginTop: "1rem"}} src='./VS Full Logo.png'/>
                        
        //                     <h1 style={{paddingTop: "2rem"}}>Login</h1>
        //                     <Form style={{width: "40%"}}  onSubmit={handleSubmit(onSubmit)}>
        //                         <Form.Group className="d-flex align-items-center justify-content-center flex-column" controlId="formLogin" bsPrefix="formGroup">
        //                             <Form.Label for='email'>Username</Form.Label>
        //                             <Form.Control placeholder='Enter username' type='text' name='email' {...register('email', {required: "Username is required"})}/>

        //                             {errors.email && <i>{errors.email.message}</i>}
                                
        //                             <Form.Label for='key'>Password</Form.Label>
        //                             <Form.Control placeholder='Enter password' type='password' name='key' {...register('key', {required: "Password is required"})}/>
                                
                                    
        //                             {errors.key && <i>{errors.key.message}</i>}

        //                             <Button type='submit' variant="secondary" style={{marginTop: "1rem"}} disabled={loading}>Login</Button> 
        //                         </Form.Group>       
        //                     </Form>

        //                     {error ? <h3 style={{color: "red"}}>{error}</h3> : null}

        //                 </Card>
        //             </Container>
        //         </Col>
        //     </Row>
        // </Container>
    )
    
}

export default Login;