import React from 'react';
import {useParams, Redirect, useNavigate} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {loginUser, getUserInfo} from '../Authentication/userSlice';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ResetPassword from './ResetPassword';
import UpdatePhoneNumber from './UpdatePhoneNumber';

function UserSettings(props) {
    const { userInfo } = useSelector((state) => state.user)
    const dispatch = useDispatch();
   
    //first render - get updated userInfo
    useEffect(() => {

        dispatch(getUserInfo());
    }, [])

    // //rerender page if userInfo is updated
    // useEffect(() => {

    //     console.log("reload page!!!")
    // }, [userInfo])


    return (
        <Container>
            <h1>Account Settings</h1>
            <br/>
            <ResetPassword/>
            <UpdatePhoneNumber/>
        </Container>

    )
    
}

export default UserSettings;