import { useEffect, useState, useRef } from 'react';
import 'datatables.net-buttons';
import BasicDataTableNet from '../BasicDataTableNet';
import axiosWithAuth from '../utils/axiosWithAuth';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';

function OrganizationsProjects (props) {

    const [tableData, setTableData] = useState([]);

    var data = {
        "organizationId": props.organizationId
    }


    //Get all users on page render
    useEffect(() => {

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}GetOrgProjects`, data)
        .then(response => {
            //check if data exists
            //create table data array for datatables.net format
            if (response.data.length > 0) {
                var data = response.data.map(data => {
                    return [
                        `<a style="text-decoration: none; color: #f7941d; font-weight: bold;" href="/Project/${data.projGUID}">${data.name}</a>`,
                        data.status,
                        `<a href="/Organization/${props.organizationId}/Project/${data.projGUID}/AssignUserProject"><Button>Assign User</Button></a>
                         <a href="/Organization/${props.organizationId}/Project/${data.projGUID}/AssignDeviceProject"><Button>Assign Device</Button></a>`
                    ]
                });
                setTableData(data);
            }
        })
        .catch(error => {
            console.log("ERROR", error)
        })
    }, [])

    return(      
        <>
            <BasicDataTableNet tableHeaders={{name: 'Project Name', status: "Status", "": ""}} tableData={tableData}/>         
        </>
    )

}

export default OrganizationsProjects;