import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProtectedRoute = ({ children }) => {
  const { loginSuccess } = useSelector((state) => state.user)
  if (!loginSuccess) {
    // user is not authenticated
    return <Navigate to="/Login" />;
  }
  return children;
};