import { useEffect, useState, useRef } from 'react';
import 'datatables.net-buttons';
import BasicDataTableNet from '../BasicDataTableNet';
import axiosWithAuth from '../utils/axiosWithAuth';
import {useNavigate, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import {Link} from 'react-router-dom';
import ReactDOMServer from 'react-dom/server'
import Table from 'react-bootstrap/Table';



function UserAccess (props) {

    const [tableData, setTableData] = useState([]);
    const [devices, setDevices] = useState([]);
    const navigate = useNavigate();
    const {userId} = useParams();

    var data = {
        "userId": userId
    }

    var revokeAccess = (deviceId) => {
        console.log("REVOKED ACCESS: ", deviceId);

        //to-do: call revokeDeviceFromUser
    }

    //Get all users on page render
    useEffect(() => {

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}GetUserDeviceAccess`, data)
        .then(response => {
            //check if data exists
            //create table data array for datatables.net format
            
            if (response.data.length > 0) {
                var data = response.data.map(data => {
                    return [
                        `<p>${data}</p>`   
                        //can't get datatables.net format to work with onclick of button - leaving in here for future implementation 
                        // `<p">${data}</p><a><Button>Remove Access</Button></a>`
                        //ReactDOMServer.renderToString(<><p>{data}</p><Button onClick={() => revokeAccess(data)}>Remove Access</Button></>)
                    ]
                });
                setTableData(data);
            }
            setDevices(response.data)
        })
        .catch(error => {
            console.log("ERROR", error)
        })

    }, [])

    return(      
        <>
            <Button style={{margin: "2rem 0rem"}} onClick={() => navigate(-1)}>Back</Button> 
            <h1 style={{paddingTop: "2rem"}}>User Access</h1>
            
            <h2>{}</h2>
            <BasicDataTableNet tableHeaders={{name: 'Device Name'}} tableData={tableData}/>         

            {/* 
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Device Name</th>
                    </tr>
                </thead>
                <tbody>
                    {devices.map(element => (
                        <tr>
                            <td>{element}<Button style={{margin: "0rem 20rem"}} onClick={() => revokeAccess(element)}>Remove Access</Button></td>
                        </tr>
                    ))}
                </tbody>
            </Table> */}
        </>
    )

}

export default UserAccess;