import { useEffect, useState } from "react";
import axiosWithAuth from "../utils/axiosWithAuth";
import Table from 'react-bootstrap/Table';
import {Link} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DeviceListTable from "./DeviceListTable";
import Spinner from 'react-bootstrap/Spinner';

function DevicesList (props) {

    const [dataByDeviceType, setDataByDeviceType] = useState([]);
    const [uniqueDeviceTypes, setUniqueDeviceTypes] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}getdevicelist`)
        .then(response => {

            var group = response.data.reduce((acc, item) => {
                if (!acc[item.deviceType]) {
                    acc[item.deviceType] = [];
                }

                acc[item.deviceType].push(item);
                return acc;
            }, {})

            //console.log("Group: ", group)
            // var result = response.data.reduce(function (r, a) {
            //     r[a.organization] = r[a.organization] || [];
            //     r[a.organization].push(a);
            //     return r;
            // }, Object.create(null));
            //console.log("Result from reduce: ", result);

            setDataByDeviceType(group);
            setUniqueDeviceTypes(Object.keys(group));

            setIsLoading(false);

        })
        .catch(error => {
            console.log("error: ", error);
            setIsLoading(false);
        })
    },[])


    const [uniqueProjects, setUniqueProjects] = useState([]);

    useEffect(() => {
        axiosWithAuth().get(`${process.env.REACT_APP_API_URL}GetUserOrgsAndProjects`)
        .then(response => {
            setUniqueProjects(response.data.projects);
        })
    }, [])


   

        return(      
            <>
                <Container fluid>
                    <Row className="my-4">
                        <h1>Devices</h1>
                    </Row>

                    {isLoading ? 
                        <Container className="d-flex align-items-center justify-content-center">      
                            <Spinner animation="border"/>
                        </Container>
                        :
                        <Row>
                            <Tabs>
                                
                                {uniqueDeviceTypes.map(key => (
                                    <Tab eventKey={key} title={key.charAt(0).toUpperCase() + key.slice(1)}>
                                        <DeviceListTable data={dataByDeviceType[key]}/> 
                                    </Tab>    
                                                    
                                ))}
                                
                            </Tabs>
                        </Row>
                   }
                    
                </Container>
            </>
        )
    
}

export default DevicesList;