import {useForm} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import axiosWithAuth from '../utils/axiosWithAuth';
import {useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

function CreateProject (props) {

    const navigate = useNavigate();
    const {organizationId} = useParams();
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const onSubmit = (data, e) => {

        setLoading(true);

        data['organizationId'] = organizationId;

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}CreateProject`, data)
        .then(response => {
            setLoading(false);
            setMessage("Project created successfully");
        })
        .catch(error => {
            setLoading(false);
            setMessage("Error - Project was not created");
        })
    }

    return(  
        <>  
            <Container >
                <Button style={{margin: "2rem 0rem"}} onClick={() => navigate(-1)}>Back</Button> 

                <Card className=" p-5 d-flex align-items-center justify-content-center">
                               
                    <h1 style={{paddingTop: "2rem"}}>Create Project</h1>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="d-flex align-items-center justify-content-center flex-column" controlId="formCreateProject" bsPrefix="formGroup">

                            <div style={{display: "flex"}}>
                                <div style={{padding: "2rem"}}>
                                    <Form.Label for='projectName'>Project Name</Form.Label>
                                    <Form.Control placeholder='Enter project name' type='text' name='projectName' {...register('projectName', {required: <p>Name is required</p>})}/>

                                    {errors.projectName && <i>{errors.projectName.message}</i>}
                                </div>

                            </div>
                            <Button disabled={loading} type='submit' variant="secondary" style={{marginTop: "1rem"}}>Create</Button> 
                        </Form.Group>       
                    </Form>

                    {loading &&
                        <Spinner animation="border"/>
                    }

                    <p>{message}</p>

                </Card>
            </Container>
        </>  
    )
}

export default CreateProject;