import {useState, useEffect} from "react";
import axiosWithAuth from '../utils/axiosWithAuth';
import {useForm} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import {useNavigate} from 'react-router-dom';


function AssignUserToDevice (props) {

    const {register, handleSubmit, formState: {errors}} = useForm();
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    // const [usersList, setUsersList] = useState([]);
    // const [devicesList, setDevicesList] = useState([]);


    var usersList = props.usersList;
    var devicesList = props.devicesList;

    const onSubmit = (data, e) => {

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}AssignUserToDevice`, data)
        .then(response => {
            setMessage("Successfully assigned user access to device")
        })
        .catch(error => {
            setMessage("Failed to assign user access to device")
        })
    }


    return(  
        <>  
        <Container >
            
            <Button style={{margin: "2rem 0rem"}} onClick={() => navigate(-1)}>Back</Button> 

            <Card className=" p-5 d-flex align-items-center justify-content-center">
                           
                <h1 style={{paddingTop: "2rem"}}>Assign user to device</h1>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="d-flex align-items-center justify-content-center flex-column" controlId="formCreateUser" bsPrefix="formGroup">

                        <div style={{display: "flex"}}>
                            <div style={{padding: "2rem"}}>
                                <Form.Label for='devices'>Select Device</Form.Label>
                                <Form.Select name="devices" {...register("deviceId", {validate: {confirmSelection: v => v != '' || <><i>Please select device</i><br/></>}})}>
                                    <option selected disabled hidden style={{display: "none"}} value=''></option>
                                    {devicesList.map(device => (
                                        <option value={device}>{device}</option>
                                    ))}
                                </Form.Select>
                                {errors.deviceId && <i>{errors.deviceId.message}</i>}
                            </div>

                            <div style={{padding: "2rem"}}>
                                <Form.Label for='users'>Select User</Form.Label>
                                <Form.Select name="users" {...register("userId", {validate: {confirmSelection: v => v != '' || <><i>Please select user</i><br/></>}})}>
                                    <option selected disabled hidden style={{display: "none"}} value=''></option>
                                    {usersList.map(user => (
                                        <option value={user.userGUID}>{user.firstName} {user.lastName}</option>
                                    ))}
                                </Form.Select>
                                {errors.userId && <i>{errors.userId.message}</i>}
                            </div>
                        </div>
                        
                        <Button type='submit' variant="secondary" style={{marginTop: "1rem"}}>Submit</Button> 
                    </Form.Group>       
                </Form>

                <p>{message}</p>

            </Card>
        </Container>
    </>  
    )
}

export default AssignUserToDevice;