import React from 'react';
import Hammer from 'hammerjs'; //Needed before chartjs zoom to make pan work
import { useEffect, useState, useRef } from 'react';
import txt from '../Device/data.json';
import {CategoryScale, Chart as ChartJS, LinearScale, LineElement, PointElement, Title, Tooltip, Legend} from 'chart.js';
import {Line} from 'react-chartjs-2';
import moment from 'moment';
import 'moment-timezone';
import zoomPlugin from 'chartjs-plugin-zoom';
import DataDurationIntervalForm from "./DataDurationIntervalForm";
import 'chartjs-adapter-date-fns';
import 'date-fns';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin
);

function DeviceDataChart (props) {

    const [autoScale, setAutoScale] = useState(false);
    
    var vaporYAxesRange = {};
    var YAxesScales = {};

    //list of colors available for lines
    var colors = [
    'rgba(255, 99, 132, 0.5)', //red
    'rgba(53, 162, 235, 0.5)', //blue
    //'rgba(247, 209, 104, 0.5)', //yellow
    'rgba(30, 73, 156, 0.5)', //dark blue
    'rgba(53, 181, 72, 0.5)', //green
    'rgba(252, 144, 3, 0.5)' //orange
    // 'rgb(255, 99, 132)',
    // 'rgb(255, 159, 64)',
    // 'rgb(255, 205, 86)',
    // 'rgb(75, 192, 192)',
    // 'rgb(54, 162, 235)',
    ]

    //Vapor device
    if (props.deviceId.startsWith('A')) {  
        if (autoScale == true) {
            YAxesScales.y4 = {offset: true, position: 'right', ticks: {color: colors[4], font: {size: 14, weight: "bold"}}}; //barometric pressure
            YAxesScales.y0 = {offset: true, ticks: {color: colors[0], font: {size: 14, weight: "bold"}}}; //amps
        }
        else {
            YAxesScales.y4 = {offset: true, min: 950, max: 1050, position: 'right', ticks: {color: colors[4], font: {size: 14, weight: "bold"}}}; //barometric pressure
            YAxesScales.y0 = {offset: true, min: 0, max: 5, ticks: {color: colors[0], font: {size: 14, weight: "bold"}}}; //amps
        }
        YAxesScales.y3 = {offset: true, position: 'right', ticks: {color: colors[3], font: {size: 14, weight: "bold"}}}; //humidity
        YAxesScales.y2 = {offset: true, ticks: {color: colors[2], font: {size: 14, weight: "bold"} }}; //temperature
        YAxesScales.y1 = {offset: true, ticks: {color: colors[1], font: {size: 14, weight: "bold"}}}; //pressure
    }
    //Sub slab device
    else if (props.deviceId.startsWith('S')) {
        if (autoScale == true) {
            YAxesScales.y0 = {offset: true, ticks: {color: colors[0], font: {size: 14, weight: "bold"}}}; //pressure
        }
        else {
            YAxesScales.y0 = {offset: true, min: -0.5, max: 0.5, ticks: {color: colors[0], font: {size: 14, weight: "bold"}}}; //pressure
        }
        YAxesScales.y1 = { offset: true, ticks: { color: colors[1], font: { size: 14, weight: "bold" } } }; //temp
        YAxesScales.y2 = { position: 'right', ticks: { color: colors[2], font: { size: 14, weight: "bold" } } }; //Battery Percent
        YAxesScales.y3 = { position: 'right', ticks: { color: colors[3], font: { size: 14, weight: "bold" } } }; //Voltage
    }
    //Radon device
    else if (props.deviceId.startsWith('R')) { 
        YAxesScales.y2 = {position: 'right', ticks: {color: colors[2], font: {size: 14, weight: "bold"} }}; //humidity
        YAxesScales.y3 = {position: 'right', ticks: {color: colors[3], font: {size: 14, weight: "bold"}}}; //bias
        YAxesScales.y1 = {ticks: {color: colors[1], font: {size: 14, weight: "bold"}}}; //temperature
        YAxesScales.y0 = {ticks: {color: colors[0], font: {size: 14, weight: "bold"}}}; //strikecount
    }


    const options = {
    spanGaps: 1000 * 60 * 60 * 24 * 90, //90 days
    responsive: true,
    interaction: {
        mode: 'index',
        intersect: false,
    },
    scales: YAxesScales,
    stacked: false,      
    plugins: {
        legend: {
            position: 'top',
            onClick: ToggleDatasetVisibility
        },
        title: {
            display: true,
            text: 'Averaged Data',
        },
        zoom: {
            pan: {
                enabled: true,
                mode: 'xy',
            },
            zoom: {
                
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true
                },
                mode: 'xy',
            }
        }
    },
};

// export const plugins = [{
//         beforeLayout: (chart) => {
//             chart.data.datasets.forEach((ds, i) => chart.config.options.scales.yAxes[i].display = !ds._meta[0].hidden)
//         }
// }];


var data = []

//check if data exists
if (props.data.length > 0) {

    //grab timestamps
    const timestamps = props.data.map(element => (
        moment.utc(element.sensedUTC).tz("America/Kentucky/Louisville").format('MM/DD/YYYY LT')
    ));

    //grab all keys for all properties in dataset
    var dataKeys = Object.keys(props.data[0].data);

    //create object to store arrays of data for each property 
    var dataCombined = {}; 
    dataKeys.forEach((key, index) => {
        props.data.forEach((dataRecord, index2) => {
            if (key in dataCombined) {
                dataCombined[key].push(dataRecord.data[key])
            }
            else {
                dataCombined[key] = [dataRecord.data[key]]
            }
        })
    })

 


    //create data for chart
    data = {
        labels: timestamps,
        datasets: dataKeys.map( (element, index) => (
            {
                label: element,
                data: dataCombined[element],
                backgroundColor: colors[index],
                borderColor: colors[index],
                yAxisID: `y${index}`,
                pointRadius: 0
            }
        ))
    }

}

//function to override/add to base functionality of toggling a dataset's visibility
function ToggleDatasetVisibility(e, legendItem, legend) {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;
    if (ci.isDatasetVisible(index)) {
        ci.hide(index);
        legendItem.hidden = true;

        //following 2 lines override basic functionality of legend:Onclick in order to toggle associated y-axis of the data
        ci.config.options.scales[`y${index}`].display = false;
        ci.update();
    } else {
        ci.show(index);
        legendItem.hidden = false;

        //following 2 lines override basic functionality of legend:Onclick in order to toggle associated y-axis of the data
        ci.config.options.scales[`y${index}`].display = true;
        ci.update();
    }
}


//------------------------------------------------------------------------------------------------------------------

    const chartRef = useRef(null);

    const handleResetZoom = () => {
        if (chartRef && chartRef.current) {
          chartRef.current.resetZoom();
        }
      };

    // useEffect (() => {
    //     const chart = chartRef.current;

    //     if(chart) {
    //         console.log("ChartJS", chart);
    //         // console.log("-------------------", chart.data.datasets.filter((ds, i) => chart.isDatasetVisible(i) ? ds : undefined))
    //     }
    // }, [])


    if (props.data.length == 0) {
        return(
            <div>
                <h1>No Data</h1>
            </div>
        )
    }
    else {

        return(      
            <>
                <div className="DeviceDataChart" style={{width: "100%"}}>
                    <Line ref={chartRef} options={options} data={data}/>
                    <button onClick={e => setAutoScale(!autoScale)}>Toggle AutoScale</button>
                    <button onClick={handleResetZoom}>Reset Zoom</button>
                </div>
            </>
        )
    }
}

export default DeviceDataChart;
