import {useForm} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import axiosWithAuth from '../utils/axiosWithAuth';
import {useState} from "react";
import {useNavigate} from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';



function CreateOrganization (props) {

    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const onSubmit = (data, e) => {

        setLoading(true);
      
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}CreateOrganization`, data)
        .then(response => {
            setLoading(false);
            setMessage("Organization created successfully");
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
            setMessage("Error - Organization was not created");
        })

    }


    return(  
        <>  
            <Container >

                <Button style={{margin: "2rem 0rem"}} onClick={() => navigate(-1)}>Back</Button> 

                <Card className=" p-5 d-flex align-items-center justify-content-center">
                               
                    <h1 style={{paddingTop: "2rem"}}>Create Organization</h1>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="d-flex align-items-center justify-content-center flex-column" controlId="formCreateUser" bsPrefix="formGroup">

                            <div style={{display: "flex"}}>
                                <div style={{padding: "2rem"}}>
                                    <Form.Label for='entity'>Organization Name</Form.Label>
                                    <Form.Control placeholder='Enter organization name' type='text' name='organizationName' {...register('organizationName', {required: <p>Name is required</p>})}/>

                                    {errors.organizationName && <i>{errors.organizationName.message}</i>}
                                </div>

                            </div>
                            <Button disabled={loading} type='submit' variant="secondary" style={{marginTop: "1rem"}}>Create</Button> 
                        </Form.Group>       
                    </Form>

                    {loading &&
                        <Spinner animation="border"/>
                    }

                    <p>{message}</p>

                </Card>
            </Container>
        </>  
    )
}

export default CreateOrganization;