import React from 'react';
import {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {getUserInfo} from '../Authentication/userSlice';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import axiosWithAuth from '../utils/axiosWithAuth';

function AdminResetUserPassword(props) {

    const [message, setMessage] = useState("");
    const {register, handleSubmit, formState: {errors}, getValues} = useForm();
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state) => state.user)
    const {userId} = useParams();

   
    useEffect(() => {

    }, [])

    const onSubmit = (data, e) => {

        var request = {
            "entity": `${userId}`,
            "value": data.newValue,
            "action": "update"
        }

        axiosWithAuth().post(`${process.env.REACT_APP_API_AUTH_URL}user`, request)
        .then(response => {
            //update message
            setMessage("Updated password successfully")
        })
        .catch(error => {
            console.log(error);
            setMessage("Error updating password");
        })

        e.target.reset();
    }

    return (
        <Container>
            {/* <h3>Reset Password</h3> */}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group bsPrefix="formGroup">
                
                    <Form.Label for='newValue'>New Password</Form.Label>
                    <Form.Control placeholder='' type='password' name='newValue' {...register('newValue', {required: <p>New password is required</p>})}/>
                
                    
                    {errors.newValue && <i>{errors.newValue.message}</i>}

                    <Form.Label for='confirmNewValue'>Confirm new password</Form.Label>
                    <Form.Control placeholder='' type='password' name='confirmNewValue' {...register('confirmNewValue', {validate: {confirmEmail: v => v === getValues().newValue || <><i>Passwords do not match</i><br/></>}} )}/>
                
                    {errors.confirmNewValue && <i>{errors.confirmNewValue.message}</i>}
                   
                    
                    <Button type='submit' variant="secondary" style={{marginTop: "1rem"}} disabled={loading}>Update Password</Button> 
                </Form.Group>       
            </Form>

            {error ? <h3 style={{color: "red"}}>{error}</h3> : null}
            <br/>
            <p>{message}</p>  

        </Container>
    )   
}

export default AdminResetUserPassword;