import {useState, useEffect} from "react";
import axiosWithAuth from '../utils/axiosWithAuth';
import {useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';


function AssignDeviceToProject (props) {

    const {register, handleSubmit, formState: {errors}} = useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [devicesList, setDevicesList] = useState([]);
    // const [projectsList, setProjectsList] = useState([]);
    const navigate = useNavigate();
    const {organizationId} = useParams();
    const {projectId} = useParams();

    var data = {
        "organizationId": organizationId
    }

    //Get organization's devices on page render
    useEffect(() => {
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}GetOrgDevices`, data)
        .then(response => {
            setDevicesList(response.data);
        })
        .catch(error => {

        })
    }, [])


    const onSubmit = (data, e) => {

        setLoading(true);

        data['projectId'] = projectId;

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}AssignDeviceToProj`, data)
        .then(response => {
            setLoading(false);
            setMessage("Successfully assigned device to project")
        })
        .catch(error => {
            setLoading(false);
            setMessage("Failed to assign device to project")
        })

        
    }


    return(  
        <>  
        <Container >
            <Button style={{margin: "2rem 0rem"}} onClick={() => navigate(-1)}>Back</Button> 
            <Card className=" p-5 d-flex align-items-center justify-content-center">
                           
                <h1 style={{paddingTop: "2rem"}}>Assign device to project</h1>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="d-flex align-items-center justify-content-center flex-column" controlId="formAssignDeviceToProject" bsPrefix="formGroup">

                        <div style={{display: "flex"}}>

                            <div style={{padding: "2rem"}}>
                                <Form.Label for='devices'>Select Device</Form.Label>
                                <Form.Select name="devices" {...register("deviceId", {validate: {confirmSelection: v => v != '' || <><i>Please select device</i><br/></>}})}>
                                    <option selected disabled hidden style={{display: "none"}} value=''></option>
                                    {devicesList.map(device => (
                                        <option value={device}>{device}</option>
                                    ))}
                                </Form.Select>
                                {errors.deviceId && <i>{errors.deviceId.message}</i>}
                            </div>

                        </div>
                        
                        <Button disabled={loading} type='submit' variant="secondary" style={{marginTop: "1rem"}}>
                            Submit
                        </Button> 
                        
                    </Form.Group>       
                </Form>

                {loading &&
                    <Spinner animation="border"/>
                }

                <p>{message}</p>

            </Card>
        </Container>
    </>  
    )
}

export default AssignDeviceToProject;