import axios from 'axios';

const apiURL = process.env.REACT_APP_API_URL;

const axiosWithAuth = () => {
    return axios.create({
        baseURL: apiURL,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }
    })
}
export default axiosWithAuth