import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import {useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import axiosWithAuth from "../../utils/axiosWithAuth";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';


function SubslabDeviceDataAlert(props) {

    const {deviceId} = useParams();
    const { userInfo } = useSelector((state) => state.user);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [batteryLowButtonText, setBatteryLowButtonText] = useState("Off");
    const [batteryLowFormDisabled, setBatteryLowFormDisabled] = useState(true);

    const [tempHighButtonText, setTempHighButtonText] = useState("Off");
    const [tempHighFormDisabled, setTempHighFormDisabled] = useState(true);

    const [tempLowButtonText, setTempLowButtonText] = useState("Off");
    const [tempLowFormDisabled, setTempLowFormDisabled] = useState(true);


    const [pressureHighButtonText, setPressureHighButtonText] = useState("Off");
    const [pressureHighFormDisabled, setPressureHighFormDisabled] = useState(true);

    const [pressureLowButtonText, setPressureLowButtonText] = useState("Off");
    const [pressureLowFormDisabled, setPressureLowFormDisabled] = useState(true);

    const { register, handleSubmit, control, formState: {errors} } = useForm({
        defaultValues: {

            batteryLow: typeof (props.preloadedValues.battery.low) != 'undefined' ? props.preloadedValues.battery.low.low : null,
            batteryHigh: typeof (props.preloadedValues.battery.high) != 'undefined' ? props.preloadedValues.battery.high.high : null,
            tempLow: typeof (props.preloadedValues.temp.low) != 'undefined' ? props.preloadedValues.temp.low.low : null,
            tempHigh: typeof (props.preloadedValues.temp.high) != 'undefined' ? props.preloadedValues.temp.high.high : null,
            pressureLow: typeof (props.preloadedValues.pressure.low) != 'undefined' ? props.preloadedValues.pressure.low.low : null,
            pressureHigh: typeof (props.preloadedValues.pressure.high) != 'undefined' ? props.preloadedValues.pressure.high.high : null,
            
        }
    });

    useEffect(() => {

        if (typeof (props.preloadedValues.battery.low) != 'undefined') {
            setBatteryLowFormDisabled(false);
            setBatteryLowButtonText("On");
        }
        if (typeof (props.preloadedValues.temp.low) != 'undefined') {
            setTempLowFormDisabled(false);
            setTempLowButtonText("On");
        }
        if (typeof (props.preloadedValues.temp.high) != 'undefined') {
            setTempHighFormDisabled(false);
            setTempHighButtonText("On");
        }
        if (typeof(props.preloadedValues.pressure.low) != 'undefined') {
            setPressureLowFormDisabled(false);
            setPressureLowButtonText("On");
        }
        if (typeof(props.preloadedValues.pressure.high) != 'undefined') {
            setPressureHighFormDisabled(false);
            setPressureHighButtonText("On");
        }
    }, []) //could putting props.preloadedValues in dependency array trigger child component changes? Look at later as possible fix.

    const onSubmit = (data, e) => {
        var requests = [];
        
        //Battery Low
        //updating an alert preference
        if (!batteryLowFormDisabled && typeof (props.preloadedValues.battery.low) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.battery.low.attribute, `battery|low|${data.batteryLow}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!batteryLowFormDisabled) {
            requests.push(createRequest("insert", `battery|low|${data.batteryLow}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (batteryLowFormDisabled && typeof(props.preloadedValues.battery.low) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.battery.low.attribute));
        }
        
 
        //Pressure Low
        //updating an alert preference
        if (!pressureLowFormDisabled && typeof(props.preloadedValues.pressure.low) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.pressure.low.attribute, `pressure|low|${data.pressureLow}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!pressureLowFormDisabled) {
            requests.push(createRequest("insert", `pressure|low|${data.pressureLow}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (pressureLowFormDisabled && typeof(props.preloadedValues.pressure.low) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.pressure.low.attribute));
        }

        //Pressure High
        //updating an alert preference
        if (!pressureHighFormDisabled && typeof(props.preloadedValues.pressure.high) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.pressure.high.attribute, `pressure|high|${data.pressureHigh}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!pressureHighFormDisabled) {
            requests.push(createRequest("insert", `pressure|high|${data.pressureHigh}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (pressureHighFormDisabled && typeof(props.preloadedValues.pressure.high) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.pressure.high.attribute));
        }

        //Temperature Low
        //updating an alert preference
        if (!tempLowFormDisabled && typeof (props.preloadedValues.temp.low) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.temp.low.attribute, `temp|low|${data.tempLow}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!tempLowFormDisabled) {
            requests.push(createRequest("insert", `temp|low|${data.tempLow}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (tempLowFormDisabled && typeof (props.preloadedValues.temp.low) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.temp.low.attribute));
        }

        //Temperature High
        //updating an alert preference
        if (!tempHighFormDisabled && typeof (props.preloadedValues.temp.high) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.temp.high.attribute, `temp|high|${data.tempHigh}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!tempHighFormDisabled) {
            requests.push(createRequest("insert", `temp|high|${data.tempHigh}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (tempHighFormDisabled && typeof (props.preloadedValues.temp.high) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.temp.high.attribute));
        }

        sendAlertRequests(requests);
    }

    function sendAlertRequests(requests) {

        Promise.all(requests.map(request => {
            return axiosWithAuth().post(`${process.env.REACT_APP_API_URL}usernotifications`, request)
                .then(response => {
                    setMessage("Changes saved successfully")
                    setIsLoading(true);
                })
                .catch(error => {
                    setMessage("Error - changes were not saved")
                    setIsLoading(true);
                })
        })).then(response => {
            //setIsLoading(false);
            //temp - reload page to reset props preloaded data
            window.location.reload();  
        })
 
    }

    const toggleButton = (setButtonText, setFormDisabled, FormDisabled) =>  {
        if (FormDisabled) {
            setButtonText("On");
            setFormDisabled(false);
        }
        else if (!FormDisabled){
            setButtonText("Off")
            setFormDisabled(true);
        }
    }

    function createRequest(requestType, attribute, newAttribute) {

        var notificationValue = props.notificationType === "email" ? `${userInfo.email}` : `${userInfo.phone[0]}`;

        var request = {
            "entity": deviceId,
            "value": notificationValue,
            "notificationType": props.notificationType,
            "action": requestType,
            "attribute": attribute
        }

        if (newAttribute) {
            request.newAttribute = newAttribute;
        }

        return request;
    }

    function checkIfToRenderSMSFormError() {

        //check to make sure user has a phone number assigned before rendering form to set text alerts
        if (props.notificationType == "sms") {
            if (userInfo.phone.length == 0) {
                return true;
            }
        }

        return false;
    }

    if (isLoading) {
        return (
            <Container className="d-flex align-items-center justify-content-center">      
                <Spinner animation="border"/>
            </Container>
        );
    }
    else {
        return(      
            <>
                {/* <h3>Device Data Alerts</h3> */}

                {!checkIfToRenderSMSFormError() ? 
                <>
                <Form onSubmit={handleSubmit(onSubmit)}>
                            
                    <Form.Group style={{display:"flex", flexDirection: "row", flexWrap: "wrap"}}  bsPrefix="formGroup">
                        


                            {/* Pressure */}
                            <div style={{display: "flex"}}>
                                <div>
                                    <Form.Label>Pressure Low</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='pressureLow' disabled={pressureLowFormDisabled} {...register('pressureLow', {required: {value: !pressureLowFormDisabled, message: <p>Pressure low threshold required</p> }})}/>
                                    {errors.pressureLow && <i>{errors.pressureLow.message}</i>}     
                                    <Form.Check 
                                        type="switch"
                                        label={pressureLowButtonText}
                                        onClick={() => toggleButton(setPressureLowButtonText, setPressureLowFormDisabled, pressureLowFormDisabled)}
                                        checked={!pressureLowFormDisabled}
                                    />
                                </div>
                                <div>
                                    <Form.Label>Pressure High</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='pressureHigh' disabled={pressureHighFormDisabled} {...register('pressureHigh', {required: {value: !pressureHighFormDisabled, message: <p>Pressure high threshold required</p> }})}/>
                                    {errors.pressureHigh && <i>{errors.pressureHigh.message}</i>}     
                                    <Form.Check 
                                        type="switch"
                                        label={pressureHighButtonText}
                                        onClick={() => toggleButton(setPressureHighButtonText, setPressureHighFormDisabled, pressureHighFormDisabled)}
                                        checked={!pressureHighFormDisabled}
                                    />
                                </div>
                            </div>

                                {/* Temperature */}
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <Form.Label>Temperature Low</Form.Label>
                                        <Form.Control placeholder='' type='number' step="0.01" name='tempLow' disabled={tempLowFormDisabled} {...register('tempLow', { required: { value: !tempLowFormDisabled, message: <p>Temp low threshold required</p> } })} />
                                        {errors.tempLow && <i>{errors.tempLow.message}</i>}
                                        <Form.Check
                                            type="switch"
                                            label={tempLowButtonText}
                                            onClick={() => toggleButton(setTempLowButtonText, setTempLowFormDisabled, tempLowFormDisabled)}
                                            checked={!tempLowFormDisabled}
                                        />
                                    </div>
                                    <div>
                                        <Form.Label>Temperature High</Form.Label>
                                        <Form.Control placeholder='' type='number' step="0.01" name='tempHigh' disabled={tempHighFormDisabled} {...register('tempHigh', { required: { value: !tempHighFormDisabled, message: <p>Temp high threshold required</p> } })} />
                                        {errors.tempHigh && <i>{errors.tempHigh.message}</i>}
                                        <Form.Check
                                            type="switch"
                                            label={tempHighButtonText}
                                            onClick={() => toggleButton(setTempHighButtonText, setTempHighFormDisabled, tempHighFormDisabled)}
                                            checked={!tempHighFormDisabled}
                                        />
                                    </div>
                                </div>

                                {/* Battery */}
                                <div style={{ display: "flex" }}>
                                    <div>
                                        <Form.Label>Battery Low</Form.Label>
                                        <Form.Control placeholder='' type='number' step="0.01" name='batteryLow' disabled={batteryLowFormDisabled} {...register('batteryLow', { required: { value: !batteryLowFormDisabled, message: <p>battery low threshold required</p> } })} />
                                        {errors.tempLow && <i>{errors.tempLow.message}</i>}
                                        <Form.Check
                                            type="switch"
                                            label={batteryLowButtonText}
                                            onClick={() => toggleButton(setBatteryLowButtonText, setBatteryLowFormDisabled, batteryLowFormDisabled)}
                                            checked={!batteryLowFormDisabled}
                                        />
                                    </div>
                                </div>

                        <Button type='submit' variant="secondary" style={{marginTop: "1rem"}}>Update</Button> 
                        

                    </Form.Group>      
                </Form>
                <p>{message}</p>  
                </>
                : <p>You must have a number associated with your account in order to set up text alerts</p>}
                   
                        
            </>
        )
    }
}

export default SubslabDeviceDataAlert