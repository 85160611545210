import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axiosWithAuth from "../utils/axiosWithAuth";
import Spinner from 'react-bootstrap/Spinner';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Chart } from 'chart.js';
import 'chartjs-adapter-date-fns';


function EditableTag(props) {

    const [loading, setLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    const handleEdit = () => {
        setIsEditable(true);
    };

    const handleChange = (event) => {
        props.onChange(event.target.value);
    };


    const handleSubmit = () => {

        var request = {
            "deviceid": props.deviceid,
            "tag": props.tag
        }

        
        //Post the new tag to the server, update if it already exists (handled via functions.)
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}posttagdata`, request)
            .then(response => {
                setLoading(false);
                props.updateDeviceData(response.data);
            })
            .catch(error => {
                setLoading(false);
                console.log("error: ", error);
            }) 
        setIsEditable(false);
    }

    return (
        <div>
            {isEditable ? (
                <input type="text" value={props.tag} onChange={handleChange} />
            ) : (
                <span>{props.tag}</span>
            )}
            <button disabled={isEditable} onClick={handleEdit} class="btn btn-primary">Edit</button>
            <button disabled={!isEditable} onClick={handleSubmit} class="btn btn-primary" type="submit">Submit</button>
        </div>
    );
}


export default EditableTag;