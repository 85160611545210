import {useForm} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import axiosWithAuth from '../utils/axiosWithAuth';
import {useState} from "react";
import Spinner from 'react-bootstrap/Spinner';



function DeviceProvision (props) {

    const {register, handleSubmit, formState: {errors}} = useForm();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");


    const onSubmit = (data, e) => {
        setLoading(true);
        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}deviceprovision`, data)
        .then(response => {
            setLoading(false);
            setMessage("Device provisioned successfully");
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
            setMessage("Error - Device was not provisioned");
        })
    }


    return(  
        <>  
            <Container >
                <Card className=" p-5 d-flex align-items-center justify-content-center">
                               
                    <h1 style={{paddingTop: "2rem"}}>Provision New Device</h1>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="d-flex align-items-center justify-content-center flex-column" controlId="formDeviceProvision" bsPrefix="formGroup">

                            <div style={{display: "flex"}}>
                                <div style={{padding: "2rem"}}>
                                    <Form.Label for='deviceid'>Device ID</Form.Label>
                                    <Form.Control placeholder='Enter device ID' type='text' name='deviceid' {...register('deviceid', {required: <p>Device ID is required</p>})}/>

                                    {errors.deviceid && <i>{errors.deviceid.message}</i>}

                                    {/*<Form.Label for='devicetag'>Device Tag</Form.Label>
                                    <Form.Control placeholder='Enter device Tag' type='text' name='devicetag' {...register('devicetag', { required: <p>Device ID is required</p> })} />

                                    {errors.devicetag && <i>{errors.devicetag.message}</i>} */}
                                
                                    {/* <Form.Label for='devicetype'>Device Type</Form.Label>
                                    <Form.Control placeholder='Enter device type' type='text' name='devicetype' {...register('devicetype', {required: <p>Device Type is required</p>})}/> */}

                                    <div>
                                        <Form.Label for='devicetype'>Select Device Type</Form.Label>
                                        <Form.Select name="devicetype" {...register("devicetype", {validate: {confirmSelection: v => v != '' || <><i>Please select device type</i><br/></>}})}>
                                            <option selected disabled hidden style={{display: "none"}} value=''></option>
                                            <option value="vapor">Vapor</option>
                                            <option value="subslab">Subslab</option>
                                        </Form.Select>
                                        {errors.devicetype && <i>{errors.devicetype.message}</i>}
                                    </div>
                                
                                    {errors.devicetype && <i>{errors.devicetype.message}</i>}
                    
                                    <Form.Label for='deviceversion'>Device Version</Form.Label>
                                    <Form.Control placeholder='Enter device version' type='text' name='deviceversion' {...register('deviceversion', {required: <p>Device Version is required</p>})}/>

                                    {errors.deviceversion && <i>{errors.deviceversion.message}</i>}
                                
                                </div>

                            </div>
                            <Button disabled={loading} type='submit' variant="secondary" style={{marginTop: "1rem"}}>Create</Button> 
                        </Form.Group>       
                    </Form>

                    {loading &&
                        <Spinner animation="border"/>
                    }

                    <p>{message}</p>


                </Card>
            </Container>
        </>  
    )
}

export default DeviceProvision;