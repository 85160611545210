import {useForm} from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import axiosWithAuth from './utils/axiosWithAuth';
import {useState, useEffect} from "react";
import {Link, useNavigate, useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import BasicDataTableNet from './BasicDataTableNet';

function AdminDashboard (props) {

    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [message, setMessage] = useState("");
    const { userInfo } = useSelector((state) => state.user)
    const [orgs, setOrgs] = useState([]);
    const [projects, setProjects] = useState([]);


 

    //Get organization and projects users on page render
    useEffect(() => {
        axiosWithAuth().get(`${process.env.REACT_APP_API_URL}GetUserOrgsAndProjects`)
        .then(response => {
            //check for orgs
            var orgs = userInfo.isOrgAdmin.map(x => {
                var orgObject = response.data.organizations.find(o => o.orgGUID == x)
                if (orgObject) {
                    return [
                        `<a style="text-decoration: none; color: #f7941d; font-weight: bold;" href="/Organization/${orgObject.orgGUID}">${orgObject.orgName}</a>`,
                        orgObject.status         
                    ]
                }
            })
            setOrgs(orgs);

            //check for projects
            var projects = userInfo.isProjAdmin.map(x => {
                var projObject = response.data.projects.find(o => o.projGUID == x)
                if (projObject) {
                    return [
                        `<a style="text-decoration: none; color: #f7941d; font-weight: bold;" href="/Project/${projObject.projGUID}">${projObject.projName}</a>`,
                        projObject.status         
                    ]
                }
            })
            setProjects(projects);

        })
        .catch(error => {
            console.log(error);
        })
    }, [])


    return(  
        <>  
            <Container >
                <h1>Admin Dashboard</h1>
                <br/>
                <br/>

                <Link to="/CreateUser"><Button style={{margin: "2rem 1rem"}}>Create New User</Button></Link>

                {orgs.length > 0 ?
                <>
                    <h2>Organizations</h2>
                    <BasicDataTableNet tableHeaders={{orgName: 'Organization Name', status: 'Status'}} tableData={orgs}/>
                </>      
                :
                ""
                }

                {projects.length > 0 ?
                <>
                    <h2>Projects</h2>
                    <BasicDataTableNet tableHeaders={{name: 'Project Name', status: "Status", "": ""}} tableData={projects}/>   
                </>          
                :
                ""
                }

            </Container>
        </>  
    )
}

export default AdminDashboard;