import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from 'axios';
import axiosWithAuth from "../utils/axiosWithAuth";

const apiURL = process.env.REACT_APP_API_AUTH_URL;

const apiURLFunc = process.env.REACT_APP_API_URL;


export const loginUser = createAsyncThunk(
  'user/login',
  async (credentials, thunkAPI) => {
     try {

        return axios.post(`${apiURL}authToken`, credentials)
        .then(response => {
            localStorage.setItem('token', response.data.token)
            //return response.data;
            //return credentials.email; //TEMP - until getUserInfo backend call works. Remove this line later and uncomment above line
            return;
        })
        .catch(error => {         
            return thunkAPI.rejectWithValue(error.response.data);
        })
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  'user/getUserInfo',
  async (thunkAPI) => {
    try {
        return axiosWithAuth().get(`${apiURLFunc}basicuserinfo`)
        .then(response => {
            return response.data;
            
        })
        .catch(error => {         
            return thunkAPI.rejectWithValue(error.response.data);
        })
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);



const userSlice = createSlice({
    name: 'user',
    initialState: { 
      userInfo: {
        firstName: "",
        lastName: "",
        email: "",
        isSysAdmin: false,
        phone: [],
        guid: ""
      },
      loading: false,
      error: null,
      loginSuccess: false
    },
    reducers: {
        // setCredentials: (state, action) => {
        //     const { user, accessToken } = action.payload
        //     state.user = user
        //     state.token = accessToken
        // },
        logOut: (state, action) => {
            state.userInfo = {
              firstName: "",
              lastName: "",
              email: "",
              isSysAdmin: false,
              phone: [],
              guid: ""
            }
            state.loginSuccess = false
            // localStorage.clear('token')
            localStorage.clear(); //clears all stored in localstorage
        },

    },
    extraReducers: {
      //login user
      [loginUser.fulfilled]: (state, {payload}) => {
        
        state.loading = false;
        //state.userInfo = payload;
        state.loginSuccess = true;
        return state;
      },
      [loginUser.pending]: (state) => {
        state.loading = true;
        state.error = null;
        state.loginSuccess = false;
      },
      [loginUser.rejected]: (state, {payload}) => {
        state.loading = false;
        state.error = payload;
        state.loginSuccess = false;
        return state;
      },
      //get userInfo
      [getUserInfo.fulfilled]: (state, {payload}) => {
        //state.loading = false;
        state.userInfo = payload;
        return state;
      },
      [getUserInfo.rejected]: (state, {payload}) => {
        //state.loading = false;
        state.error = payload;
        state.userInfo = {
          firstName: "",
          lastName: "",
          email: "",
          isSysAdmin: false,
          phone: [],
          guid: ""
        }
      } 
    }
})

export const { logOut } = userSlice.actions

export default userSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
