import React from 'react';
import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {getUserInfo} from '../Authentication/userSlice';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import axiosWithAuth from '../utils/axiosWithAuth';

function EditPhoneNumber(props) {

    const [message, setMessage] = useState("");
    const dispatch = useDispatch();
    const { userInfo, loading, error } = useSelector((state) => state.user)
    const {register, handleSubmit, formState: {errors}, getValues} = useForm({
        defaultValues: {
           phoneNumber: userInfo.phone.length > 0 ? userInfo.phone[0] : null
        }
    });
   
    useEffect(() => {

    }, [])

    const onSubmit = (data, e) => {

        //create initial request variable
        var request = {
        }

        //update request parameters depending on request type
        //add phone number
        if (userInfo.phone.length == 0) {
            request.action = "insert";
            request.newPhoneNumber = data.phoneNumber;
        }
        //delete phone number 
        else if (userInfo.phone.length > 0 && data.phoneNumber == "") {
            request.action = "delete";
        }
        //edit phone number
        else if (userInfo.phone.length > 0) {
            request.action = "update";
            request.newPhoneNumber = data.phoneNumber;
        }

        axiosWithAuth().post(`${process.env.REACT_APP_API_URL}PhoneNumber`, request)
        .then(response => {

            //get updated user info
            dispatch(getUserInfo());

            //update message
            setMessage("Updated phone number successfully")
        })
        .catch(error => {
            console.log(error);
            setMessage("Error updating phone number");
        })

    }

    return (
        <Container>
            <h2>Update Phone Number</h2>
            <br/>

            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group bsPrefix="formGroup">
                    <Form.Label for='phoneNumber'>Phone number</Form.Label>
                    <Form.Control placeholder='' type='number' name='phoneNumber' {...register('phoneNumber', {validate: {confirmNumberDigits: v => /^(\d{0}|\d{10})?$/.test(v) || <><i>Phone Number must contain 10 digits. <br/> To delete your number, please delete all numbers in form and then click update.</i><br/></>}}) }/>

                    {errors.phoneNumber && <i>{errors.phoneNumber.message}</i>}                
                    
                    <Button type='submit' variant="secondary" style={{marginTop: "1rem"}} disabled={loading}>Update</Button> 
                </Form.Group>       
            </Form>

            {error ? <h3 style={{color: "red"}}>{error}</h3> : null}
            <br/>
            <p>{message}</p>  

        </Container>
    )   
}

export default EditPhoneNumber;