import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import {useNavigate, useParams} from 'react-router-dom';
import AdminResetUserPassword from "../Admin-Shared/AdminResetUserPassword";


function UserOverview(props) {
    const navigate = useNavigate();

    return (
        <Container fluid>
            <Button style={{margin: "2rem 0rem"}} onClick={() => navigate(-1)}>Back</Button> 
            <Row className="my-4">
                <h1>Reset User Password</h1>
            </Row>    
            <Row> 
                <AdminResetUserPassword/>
            </Row>      
        </Container>
    )
}


export default UserOverview;