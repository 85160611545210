import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {Link, useNavigate, useParams} from 'react-router-dom';
import OrgUsersList from './OrgUsersList';
import OrganizationsProjects from './OrganizationsProjects';
import OrgDevicesList from './OrgDevicesList';


function OrganizationDetails (props) {

    const navigate = useNavigate();
    const {organizationId} = useParams();

    return( 
        <>
        <Container fluid>
        <Button  onClick={() => navigate(-1)}>Back</Button>
                      
            <Row className="my-4">
                <h1>Organization Details</h1>
                
            </Row>   
                <Row>
                    <Tabs>

                      <Tab eventKey='Projects' title='Projects'>
                        <Link to="CreateProject"><Button style={{margin: "2rem 1rem"}}>Create New Project</Button></Link>
                        <OrganizationsProjects organizationId={organizationId}/>
                      </Tab>   

                      <Tab eventKey='Users' title='Users'>
                        {/* <Link to="/CreateUser"><Button style={{margin: "2rem 1rem"}}>Create New User</Button></Link> */}
                        <OrgUsersList organizationId={organizationId}/>
                      </Tab>  

                      <Tab eventKey='Devices' title='Devices'>
                        <OrgDevicesList organizationId={organizationId}/>
                      </Tab>

                </Tabs>
                </Row>      
        </Container>
        </>
    )
}

export default OrganizationDetails;