import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import {useParams} from 'react-router-dom';
import { useSelector } from 'react-redux';
import axiosWithAuth from "../../utils/axiosWithAuth";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';


function DeviceDataAlert(props) {

    const {deviceId} = useParams();
    const { userInfo } = useSelector((state) => state.user);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    const [baroPressureLowButtonText, setBaroPressureLowButtonText] = useState("Off");
    const [baroPressureLowFormDisabled, setBaroPressureLowFormDisabled] = useState(true);
        
    const [baroPressureHighButtonText, setBaroPressureHighButtonText] = useState("Off");
    const [baroPressureHighFormDisabled, setBaroPressureHighFormDisabled] = useState(true);
    
    const [tempHighButtonText, setTempHighButtonText] = useState("Off");
    const [tempHighFormDisabled, setTempHighFormDisabled] = useState(true);

    const [tempLowButtonText, setTempLowButtonText] = useState("Off");
    const [tempLowFormDisabled, setTempLowFormDisabled] = useState(true);

    const [ampsHighButtonText, setAmpsHighButtonText] = useState("Off");
    const [ampsHighFormDisabled, setAmpsHighFormDisabled] = useState(true);

    const [ampsLowButtonText, setAmpsLowButtonText] = useState("Off");
    const [ampsLowFormDisabled, setAmpsLowFormDisabled] = useState(true);

    const [pressureHighButtonText, setPressureHighButtonText] = useState("Off");
    const [pressureHighFormDisabled, setPressureHighFormDisabled] = useState(true);

    const [pressureLowButtonText, setPressureLowButtonText] = useState("Off");
    const [pressureLowFormDisabled, setPressureLowFormDisabled] = useState(true);

    const [humidityHighButtonText, setHumidityHighButtonText] = useState("Off");
    const [humidityHighFormDisabled, setHumidityHighFormDisabled] = useState(true);

    const [humidityLowButtonText, setHumidityLowButtonText] = useState("Off");
    const [humidityLowFormDisabled, setHumidityLowFormDisabled] = useState(true);

    const { register, handleSubmit, control, formState: {errors} } = useForm({
        defaultValues: {
            tempLow: typeof(props.preloadedValues.temp.low) != 'undefined' ? props.preloadedValues.temp.low.low : null,
            tempHigh: typeof(props.preloadedValues.temp.high) != 'undefined' ? props.preloadedValues.temp.high.high : null,
            barometricPressureLow: typeof(props.preloadedValues.barometricPressure.low) != 'undefined' ? props.preloadedValues.barometricPressure.low.low : null,
            barometricPressureHigh: typeof(props.preloadedValues.barometricPressure.high) != 'undefined' ? props.preloadedValues.barometricPressure.high.high : null,
            ampsLow: typeof(props.preloadedValues.amps.low) != 'undefined' ? props.preloadedValues.amps.low.low : null,
            ampsHigh: typeof(props.preloadedValues.amps.high) != 'undefined' ? props.preloadedValues.amps.high.high: null,
            pressureLow: typeof(props.preloadedValues.pressure.low) != 'undefined' ? props.preloadedValues.pressure.low.low : null,
            pressureHigh: typeof(props.preloadedValues.pressure.high) != 'undefined' ? props.preloadedValues.pressure.high.high : null,
            humidityLow: typeof(props.preloadedValues.humidity.low) != 'undefined' ? props.preloadedValues.humidity.low.low : null,
            humidityHigh: typeof(props.preloadedValues.humidity.high) != 'undefined' ? props.preloadedValues.humidity.high.high : null,
        }
    });

    useEffect(() => {

        //set form button as enabled/"On" if preloadedValues exist
        if (typeof(props.preloadedValues.temp.low) != 'undefined') {
            setTempLowFormDisabled(false);
            setTempLowButtonText("On");
        }
        if (typeof(props.preloadedValues.temp.high) != 'undefined') {
            setTempHighFormDisabled(false);
            setTempHighButtonText("On");
        }
        if (typeof(props.preloadedValues.barometricPressure.low) != 'undefined') {
            setBaroPressureLowFormDisabled(false);
            setBaroPressureLowButtonText("On");
        }
        if (typeof(props.preloadedValues.barometricPressure.high) != 'undefined') {
            setBaroPressureHighFormDisabled(false);
            setBaroPressureHighButtonText("On");
        }
        if (typeof(props.preloadedValues.amps.low) != 'undefined') {
            setAmpsLowFormDisabled(false);
            setAmpsLowButtonText("On");
        }
        if (typeof(props.preloadedValues.amps.high) != 'undefined') {
            setAmpsHighFormDisabled(false);
            setAmpsHighButtonText("On");
        }
        if (typeof(props.preloadedValues.pressure.low) != 'undefined') {
            setPressureLowFormDisabled(false);
            setPressureLowButtonText("On");
        }
        if (typeof(props.preloadedValues.pressure.high) != 'undefined') {
            setPressureHighFormDisabled(false);
            setPressureHighButtonText("On");
        }
        if (typeof(props.preloadedValues.humidity.low) != 'undefined') {
            setHumidityLowFormDisabled(false);
            setHumidityLowButtonText("On");
        }
        if (typeof(props.preloadedValues.humidity.high) != 'undefined') {
            setHumidityHighFormDisabled(false);
            setHumidityHighButtonText("On");
        }
    }, []) //could putting props.preloadedValues in dependency array trigger child component changes? Look at later as possible fix.

    const onSubmit = (data, e) => {
        var requests = [];

        //Temperature Low
        //updating an alert preference
        if (!tempLowFormDisabled && typeof(props.preloadedValues.temp.low) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.temp.low.attribute, `temp|low|${data.tempLow}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!tempLowFormDisabled) {
            requests.push(createRequest("insert", `temp|low|${data.tempLow}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (tempLowFormDisabled && typeof(props.preloadedValues.temp.low) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.temp.low.attribute));
        }

        //Temperature High
        //updating an alert preference
        if (!tempHighFormDisabled && typeof(props.preloadedValues.temp.high) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.temp.high.attribute, `temp|high|${data.tempHigh}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!tempHighFormDisabled) {
            requests.push(createRequest("insert", `temp|high|${data.tempHigh}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (tempHighFormDisabled && typeof(props.preloadedValues.temp.high) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.temp.high.attribute));
        }

        //Barometric Pressure Low
        //updating an alert preference
        if (!baroPressureLowFormDisabled && typeof(props.preloadedValues.barometricPressure.low) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.barometricPressure.low.attribute, `barometric|low|${data.barometricPressureLow}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!baroPressureLowFormDisabled) {
            requests.push(createRequest("insert", `barometric|low|${data.barometricPressureLow}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (baroPressureLowFormDisabled && typeof(props.preloadedValues.barometricPressure.low) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.barometricPressure.low.attribute));
        }
      
        //Barometric Pressure High
        //updating an alert preference
        if (!baroPressureHighFormDisabled && typeof(props.preloadedValues.barometricPressure.high) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.barometricPressure.high.attribute, `barometric|high|${data.barometricPressureHigh}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!baroPressureHighFormDisabled) {
            requests.push(createRequest("insert", `barometric|high|${data.barometricPressureHigh}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (baroPressureHighFormDisabled && typeof(props.preloadedValues.barometricPressure.high) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.barometricPressure.high.attribute));
        }

        //Amps Low
        //updating an alert preference
        if (!ampsLowFormDisabled && typeof(props.preloadedValues.amps.low) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.amps.low.attribute, `amps|low|${data.ampsLow}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!ampsLowFormDisabled) {
            requests.push(createRequest("insert", `amps|low|${data.ampsLow}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (ampsLowFormDisabled && typeof(props.preloadedValues.amps.low) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.amps.low.attribute));
        }

        //Amps High
        //updating an alert preference
        if (!ampsHighFormDisabled && typeof(props.preloadedValues.amps.high) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.amps.high.attribute, `amps|high|${data.ampsHigh}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!ampsHighFormDisabled) {
            requests.push(createRequest("insert", `amps|high|${data.ampsHigh}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (ampsHighFormDisabled && typeof(props.preloadedValues.amps.high) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.amps.high.attribute));
        }

        //Pressure Low
        //updating an alert preference
        if (!pressureLowFormDisabled && typeof(props.preloadedValues.pressure.low) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.pressure.low.attribute, `pressure|low|${data.pressureLow}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!pressureLowFormDisabled) {
            requests.push(createRequest("insert", `pressure|low|${data.pressureLow}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (pressureLowFormDisabled && typeof(props.preloadedValues.pressure.low) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.pressure.low.attribute));
        }

        //Pressure High
        //updating an alert preference
        if (!pressureHighFormDisabled && typeof(props.preloadedValues.pressure.high) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.pressure.high.attribute, `pressure|high|${data.pressureHigh}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!pressureHighFormDisabled) {
            requests.push(createRequest("insert", `pressure|high|${data.pressureHigh}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (pressureHighFormDisabled && typeof(props.preloadedValues.pressure.high) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.pressure.high.attribute));
        }

        //Humidity Low
        //updating an alert preference
        if (!humidityLowFormDisabled && typeof(props.preloadedValues.humidity.low) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.humidity.low.attribute, `humidity|low|${data.humidityLow}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!humidityLowFormDisabled) {
            requests.push(createRequest("insert", `humidity|low|${data.humidityLow}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (humidityLowFormDisabled && typeof(props.preloadedValues.humidity.low) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.humidity.low.attribute));
        }

        //Humidity High
        //updating an alert preference
        if (!humidityHighFormDisabled && typeof(props.preloadedValues.humidity.high) != 'undefined') {
            requests.push(createRequest("update", props.preloadedValues.humidity.high.attribute, `humidity|high|${data.humidityHigh}|${props.notificationMethod}|60`));
        }
        //inserting/creating a NEW alert preference
        else if (!humidityHighFormDisabled) {
            requests.push(createRequest("insert", `humidity|high|${data.humidityHigh}|${props.notificationMethod}|60`));
        }
        //deleting an alert preference, and alert previously existed for user
        else if (humidityHighFormDisabled && typeof(props.preloadedValues.humidity.high) != 'undefined') {
            requests.push(createRequest("delete", props.preloadedValues.humidity.high.attribute));
        }

        sendAlertRequests(requests);
    }

    function sendAlertRequests(requests) {

        Promise.all(requests.map(request => {
            return axiosWithAuth().post(`${process.env.REACT_APP_API_URL}usernotifications`, request)
                .then(response => {
                    setMessage("Changes saved successfully")
                    setIsLoading(true);
                })
                .catch(error => {
                    setMessage("Error - changes were not saved")
                    setIsLoading(true);
                })
        })).then(response => {
            //setIsLoading(false);
            //temp - reload page to reset props preloaded data
            window.location.reload();  
        })
 
    }

    const toggleButton = (setButtonText, setFormDisabled, FormDisabled) =>  {
        if (FormDisabled) {
            setButtonText("On");
            setFormDisabled(false);
        }
        else if (!FormDisabled){
            setButtonText("Off")
            setFormDisabled(true);
        }
    }

    function createRequest(requestType, attribute, newAttribute) {

        var notificationValue = props.notificationType == "email" ? `${userInfo.email}` : `${userInfo.phone[0]}`;

        var request = {
            "entity": deviceId,
            "value": notificationValue,
            "notificationType": props.notificationType,
            "action": requestType,
            "attribute": attribute
        }

        if (newAttribute) {
            request.newAttribute = newAttribute;
        }

        return request;
    }

    function checkIfToRenderSMSFormError() {

        //check to make sure user has a phone number assigned before rendering form to set text alerts
        if (props.notificationType == "sms") {
            if (userInfo.phone.length == 0) {
                return true;
            }
        }

        return false;
    }

    if (isLoading) {
        return (
            <Container className="d-flex align-items-center justify-content-center">      
                <Spinner animation="border"/>
            </Container>
        );
    }
    else {
        return(      
            <>
                {/* <h3>Device Data Alerts</h3> */}

                {!checkIfToRenderSMSFormError() ? 
                <>
                <Form onSubmit={handleSubmit(onSubmit)}>
                            
                    <Form.Group style={{display:"flex", flexDirection: "row", flexWrap: "wrap"}}  bsPrefix="formGroup">

                        <div>
                            {/* Barometric Pressure */}
                            <div style={{ display: "flex"}}>
                                <div>
                                    <Form.Label>Barometric Pressure Low</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='barometricPressureLow' disabled={baroPressureLowFormDisabled} {...register('barometricPressureLow', {required: {value: !baroPressureLowFormDisabled, message: <p>Barometric pressure low threshold required</p> }})}/>
                                    {errors.barometricPressureLow && <i>{errors.barometricPressureLow.message}</i>}    
                                    <Form.Check 
                                        type="switch"
                                        label={baroPressureLowButtonText}
                                        onClick={() => toggleButton(setBaroPressureLowButtonText, setBaroPressureLowFormDisabled, baroPressureLowFormDisabled)}
                                        checked={!baroPressureLowFormDisabled}
                                    />
                                </div>
                                <div>
                                    <Form.Label>Barometric Pressure High</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='barometricPressureHigh' disabled={baroPressureHighFormDisabled} {...register('barometricPressureHigh', {required: {value: !baroPressureHighFormDisabled, message: <p>Barometric pressure high threshold required</p> }})}/>
                                    {errors.barometricPressureHigh && <i>{errors.barometricPressureHigh.message}</i>}    
                                    <Form.Check 
                                        type="switch"
                                        label={baroPressureHighButtonText}
                                        onClick={() => toggleButton(setBaroPressureHighButtonText, setBaroPressureHighFormDisabled, baroPressureHighFormDisabled)}
                                        checked={!baroPressureHighFormDisabled}
                                    />
                                </div>
                            </div>

                            {/* Temperature */}
                            <div style={{display: "flex"}}>
                                <div>
                                    <Form.Label>Temperature Low</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='tempLow' disabled={tempLowFormDisabled} {...register('tempLow', {required: {value: !tempLowFormDisabled, message: <p>Temp low threshold required</p> }})}/>
                                    {errors.tempLow && <i>{errors.tempLow.message}</i>}     
                                    <Form.Check 
                                        type="switch"
                                        label={tempLowButtonText}
                                        onClick={() => toggleButton(setTempLowButtonText, setTempLowFormDisabled, tempLowFormDisabled)}
                                        checked={!tempLowFormDisabled}
                                    />
                                </div>
                                <div>
                                    <Form.Label>Temperature High</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='tempHigh' disabled={tempHighFormDisabled} {...register('tempHigh', {required: {value: !tempHighFormDisabled, message: <p>Temp high threshold required</p> }})}/>
                                    {errors.tempHigh && <i>{errors.tempHigh.message}</i>}     
                                    <Form.Check 
                                        type="switch"
                                        label={tempHighButtonText}
                                        onClick={() => toggleButton(setTempHighButtonText, setTempHighFormDisabled, tempHighFormDisabled)}
                                        checked={!tempHighFormDisabled}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div>
                            {/* Amps */}
                            <div style={{display: "flex"}}>
                                <div>
                                    <Form.Label>Amps Low</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='ampsLow' disabled={ampsLowFormDisabled} {...register('ampsLow', {required: {value: !ampsLowFormDisabled, message: <p>Amps low threshold required</p> }})}/>
                                    {errors.ampsLow && <i>{errors.ampsLow.message}</i>}     
                                    <Form.Check 
                                        type="switch"
                                        label={ampsLowButtonText}
                                        onClick={() => toggleButton(setAmpsLowButtonText, setAmpsLowFormDisabled, ampsLowFormDisabled)}
                                        checked={!ampsLowFormDisabled}
                                    />
                                </div>
                                <div>
                                    <Form.Label>Amps High</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='ampsHigh' disabled={ampsHighFormDisabled} {...register('ampsHigh', {required: {value: !ampsHighFormDisabled, message: <p>Amps high threshold required</p> }})}/>
                                    {errors.ampsHigh && <i>{errors.ampsHigh.message}</i>}     
                                    <Form.Check 
                                        type="switch"
                                        label={ampsHighButtonText}
                                        onClick={() => toggleButton(setAmpsHighButtonText, setAmpsHighFormDisabled, ampsHighFormDisabled)}
                                        checked={!ampsHighFormDisabled}
                                    />
                                </div>
                            </div>

                            {/* Pressure */}
                            <div style={{display: "flex"}}>
                                <div>
                                    <Form.Label>Pressure Low</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='pressureLow' disabled={pressureLowFormDisabled} {...register('pressureLow', {required: {value: !pressureLowFormDisabled, message: <p>Pressure low threshold required</p> }})}/>
                                    {errors.pressureLow && <i>{errors.pressureLow.message}</i>}     
                                    <Form.Check 
                                        type="switch"
                                        label={pressureLowButtonText}
                                        onClick={() => toggleButton(setPressureLowButtonText, setPressureLowFormDisabled, pressureLowFormDisabled)}
                                        checked={!pressureLowFormDisabled}
                                    />
                                </div>
                                <div>
                                    <Form.Label>Pressure High</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='pressureHigh' disabled={pressureHighFormDisabled} {...register('pressureHigh', {required: {value: !pressureHighFormDisabled, message: <p>Pressure high threshold required</p> }})}/>
                                    {errors.pressureHigh && <i>{errors.pressureHigh.message}</i>}     
                                    <Form.Check 
                                        type="switch"
                                        label={pressureHighButtonText}
                                        onClick={() => toggleButton(setPressureHighButtonText, setPressureHighFormDisabled, pressureHighFormDisabled)}
                                        checked={!pressureHighFormDisabled}
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            {/* Humidity */}
                            <div style={{display: "flex"}}>
                                <div>
                                    <Form.Label>Humidity Low</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='humidityLow' disabled={humidityLowFormDisabled} {...register('humidityLow', {required: {value: !humidityLowFormDisabled, message: <p>Humidity low threshold required</p> }})}/>
                                    {errors.humidityLow && <i>{errors.humidityLow.message}</i>}     
                                    <Form.Check 
                                        type="switch"
                                        label={humidityLowButtonText}
                                        onClick={() => toggleButton(setHumidityLowButtonText, setHumidityLowFormDisabled, humidityLowFormDisabled)}
                                        checked={!humidityLowFormDisabled}
                                    />
                                </div>
                                <div>
                                    <Form.Label>Humidity High</Form.Label>
                                    <Form.Control placeholder='' type='number' step="0.01" name='humidityHigh' disabled={humidityHighFormDisabled} {...register('humidityHigh', {required: {value: !humidityHighFormDisabled, message: <p>Humidity high threshold required</p> }})}/>
                                    {errors.humidityHigh && <i>{errors.humidityHigh.message}</i>}     
                                    <Form.Check 
                                        type="switch"
                                        label={humidityHighButtonText}
                                        onClick={() => toggleButton(setHumidityHighButtonText, setHumidityHighFormDisabled, humidityHighFormDisabled)}
                                        checked={!humidityHighFormDisabled}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <Button type='submit' variant="secondary" style={{marginTop: "1rem"}}>Update</Button> 
                        

                    </Form.Group>      
                </Form>
                <p>{message}</p>  
                </>
                : <p>You must have a number associated with your account in order to set up text alerts</p>}
                   
                        
            </>
        )
    }
}

export default DeviceDataAlert